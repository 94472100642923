import React from 'react'

const Footer = () => {


  return (
    <footer className='min-h-[15vh] bg-[#F7F7F8] mt-auto px-[10%]'>
        <div className='my-[5%] lg:py-8 flex flex-col space-y-5 items-center'>
            <h2 className='text-center text-lg sm:text-xl md:text-3xl font-bold text-[#7f56d9]'>Let’s work together - Say Hi 🙂</h2>
            <a className='flex items-center text-[#667085] font-medium text-base md:text-xl hover:underline' href='mailto:akerelejumoke9@gmail.com' target="_blank" rel="noopener noreferrer">
                akerelejumoke9@gmail.com
                <span>
                    <img className='w-5 opacity-55' src="/assets/diagonal-arrow.svg" alt="diagonal" />
                </span>
            </a>
        </div>

        <div className='flex flex-wrap gap-5 py-10 border-t border-[#777]/20 text-[#667085]'>
            <p className='text-xs sm:text-sm md:text-base'>© 2024 Olajumoke Akerele. All rights reserved.</p>
            <ul className='flex gap-5 text-xs sm:text-sm md:text-base'>
                <li className='footer-navlink'>Terms</li>
                <li className='footer-navlink'>Privacy</li>
                <li className='footer-navlink'>Cookies</li>
            </ul>
            <span 
                onClick={() => {
                        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                        }} 
                className='ml-auto cursor-pointer hover:scale-125 transition-all duration-150'>
                <img src="/assets/arrow-up.svg" alt="arrow-up" />
            </span>
        </div>
    </footer>
  )
}

export default Footer