import './App.css';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import Home from './routes/Home';
import About from './routes/About'
import Contact from './routes/Contact'
import ErrorPage from './routes/error-page';
import Buddie from './routes/work/Buddie';
import CarbsBuddy from './routes/work/CarbsBuddy';
import ForWheels from './routes/work/ForWheels';
import GamificationInHealth from './routes/work/GamificationInHealth';
import VersalFinance from './routes/work/VersalFinance';
import Layout from './components/Layout';

const WorkRouter = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/work",
    element: <WorkRouter />,
    children: [
      {
        index: true,
        element: <Navigate to={'/work/carbsbuddy'} />
      },
      {
        path: 'buddie',
        element: <Buddie />
      },
      {
        path: 'carbsbuddy',
        element: <CarbsBuddy />
      },
      {
        path: 'forwheels',
        element: <ForWheels />
      },
      {
        path: 'gamificationinhealth',
        element: <GamificationInHealth />
      },
      {
        path: 'versalfinance',
        element: <VersalFinance />
      }
    ]
  },
  {
    path: "/contact",
    element: <Contact />,
  }
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
