import React from 'react'
import WorkLayout from '../../components/Work/Layout'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const PageNav = [
    {
        id: 1,
        title: 'Summary',
        path: 'summary'
    },
    {
        id: 2,
        title: 'Overview',
        path: 'overview'
    },
    {
        id: 3,
        title: 'Market Research',
        path: 'market-research'
    },
    {
        id: 4,
        title: 'User Research',
        path: 'user-research'
    },
    {
        id: 5,
        title: 'Information Architecture',
        path: 'information-architecture'
    }, 
    {
        id: 6, 
        title: 'Sketches',
        path: 'sketches'
    },
    {
        id: 7,
        title: 'Style Components',
        path: 'style-components'
    },
    {
        id: 8,
        title: 'High fidelity designs',
        path: 'high-fidelity'
    }, 
    {
        id: 9,
        title: 'User testing',
        path: 'user-testing'
    }, 
    {
        id: 10,
        title: 'Evaluation', 
        path: 'evaluation'
    }, 
    {
        id: 11,
        title: 'Iterations',
        path: 'iterations'
    },
    {
        id: 12,
        title: 'Limitations',
        path: 'limitations'
    },
]

const ForWheels = () => {
  return (
    <WorkLayout PageNav={PageNav} coverImg='/assets/work/forwheels.png'>
        <div 
            className='md:max-w-[70%] py-10 flex flex-col justify-between gap-5 min-h-screen'
        >
            <div id='summary'>
                <h1 className='text-3xl md:text-5xl leading-snug font-nunito pb-8'>ForWheels, where learning meets innovation on the road to mastery.</h1>
                <p className='font-bold text-lg my-2'>Summary</p>
                <p className='text-justify'>Imagine unlocking the secrets of automotive mechanics with just a tap on your screen. ForWheels brings this vision to life, transforming the way we learn and interact with cars. Through the lens of augmented reality, it doesn't just teach; it immerses you in a world where every component has a story, every tool a purpose. This isn't just education — it's an adventure into the heart of automotive design, engineering, and maintenance, making the complex world of cars accessible and thrilling for everyone. </p>
            </div>
            <div id='overview' className='my-10'>
                <h1 className='work-subhead'>Overview</h1>
                <LazyLoadImage 
                    src='/assets/work/forwheels/overview.png'
                    className='w-full h-full'
                    effect='blur'
                    alt='alt'
                />
                <p className='text-justify my-10'>Cars are a huge part of our lives, societies and civilization at large. They make life easier, they’re symbols of status, and they inspire children to dream.</p>
                <p className='text-justify'>However, there are gaps in this relationship between man and machine. Many car owners have poor maintenance habits, many engineering students struggle to learn because they see more equations than actual cars while studying, and training technicians is expensive and hazardous, with risk of heavy artillery misuse leading to life-changing injuries and death sometimes.</p>
            </div>
            <div id='market-research'  className='min-h-full py-8'>
                <h1 className='work-subhead'>Market Research</h1>
                <LazyLoadImage
                    className='w-full h-full' 
                    src='/assets/work/forwheels/market-research.png'
                    effect='blur'
                    alt='alt'
                />
                <p className='text-justify my-10'>Prior to starting the design of forwheels, extensive market research was conducted  to gather information about the current state of automotive education and training at the time. The goal of the research was to identify current weaknesses, new market trends, and possible competitors in order to provide guidance for creating an efficient augmented reality application.</p>
                <span className='block text-2xl font-bold my-7'>Findings</span>
                <LazyLoadImage 
                    src='/assets/work/forwheels/findings.png'
                    className='w-full h-full'
                    effect='blur'
                    alt='alt'
                />
            </div>
             <div id='user-research'  className='min-h-full'>
                <h1 className='work-subhead'>User Research</h1>
                <LazyLoadImage 
                    src='/assets/work/forwheels/user-research.png'
                    className='w-full h-full py-10'
                    effect='blur'
                    alt='alt'
                />
                <p className='text-justify py-6'>After identifying the business's needs through market research, we conducted user interviews with individuals aligned with the product's goals. This helped us understand their training needs and the challenges they face.
                </p>
                <p className='text-justify'>With two objectives in mind. The first being to gain a deeper understanding of preferred learning methods, and the second was to learn about prevalent habits pertaining to vehicle ownership and maintenance.</p>
                <div className='px-4 md:px-0 py-8'>
                    <p className='font-bold'>Target audience</p>
                    <ul className='list-disc py-4'>
                        <li>Automotive Engineers</li>
                        <li>Mechanics and Maintenance Professionals</li>
                        <li>Car Owners</li>
                        <li>Learning Specialists/ Engineering Student</li>
                    </ul>
                </div>
                <div className='py-8'>
                    <span className='font-bold md:text-[28px]'>Empathy mapping</span>
                    <p className='text-justify py-10'>To effectively organize and analyze the wealth of information gathered from the interview, we employed the say-think-feel-do framework, allowing us to not only categorize their responses but also to capture the emotions behind their pain points.</p>
                    <LazyLoadImage 
                         src='/assets/work/forwheels/empathy-mapping.png'
                         className='w-full h-full'
                         effect='blur'
                         alt='alt'
                    />
                </div>
                <div className='py-8'>
                    <span className='block md:text-[28px] font-bold py-7'>Key realisations</span>
                    <p>Here are the key realisations and takeaways from the interviews and individuals that was spoken to, which was then categorised into several groups, which include:</p>
                    <div className='py-10'>
                        <ul className='space-y-10'>
                            <div className='flex flex-col md:flex-row justify-between'>
                                <h3 className='font-bold'>01. The eyes have it</h3>
                                <div className='md:w-[65%]'>
                                    <p className='text-justify'>Mirror neurons play a huge part in the human learning experience, especially in children. Mimicry is one of the earliest methods of learning we practice.</p>
                                    <ul className='text-[#2B2B2B] list-disc px-4 md:px-0 md:ml-10 w-full'>
                                        <li>“I learn by observing what others are doing e.g. mechanics”</li>
                                        <li>“I enjoy the practical sessions more than the theoretic ones”</li>
                                        <li>“I find it easier to learn by watching people than listening to explanations.</li>
                                        <li>“I get sleepy when people explain to me.”</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='flex flex-col md:flex-row justify-between'>
                                <h3 className='font-bold'>02. Images over text</h3>
                                <div className='md:w-[65%]'>
                                    <p className='text-justify'>The human brain processes images 60,000 times faster than text, and 90% of the information we recieve from the world around us is visual.</p>
                                    <ul className='text-[#2B2B2B] list-disc px-4 md:px-0 md:ml-10 w-full'>
                                        <li>“I learn better when I watch tutorials on youtube because it allows me to follow step-by-step”</li>
                                        <li>“I enjoy the practical sessions more than the theoretic ones”</li>
                                        <li>“I love when lecturers demonstrate whatever they are trying to explain, I usually don't forget afterwards.”</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='flex flex-col md:flex-row  justify-between'>
                                <h3 className='font-bold'>03. Cars need attention</h3>
                                <div className='md:w-[65%]'>
                                    <p className='text-justify'>It is not enough to just own a car. You have to cater to your car attentively, to get the most out of your experience with it. </p>
                                    <ul className='text-[#2B2B2B] list-disc px-4 md:px-0 md:ml-10 w-full'>
                                        <li>“I always follow the appointment to maintain my car”</li>
                                        <li>“Whenever i'm driving i'm always observant and I feel when something is wrong.”</li>
                                        <li>“I know my car has problems and needs maintenance by looking at the lights on the dashboard”</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='flex flex-col md:flex-row  justify-between'>
                                <h3 className='font-bold'>04. Confidence over time</h3>
                                <div className='md:w-[65%]'>
                                    <p className='text-justify'>The more people do things and the more tasks people complete, the more self assured and increasingly confident they become.</p>
                                    <ul className='text-[#2B2B2B] list-disc px-4 md:px-0 md:ml-10 w-full'>
                                        <li>“Finishing things has helped me lose my fear of making mistakes”</li>
                                        <li>“I didn’t have much self belief when I started out, but after a year I’m able to confidently diagnose the car, buy parts and take the engine apart if need be”</li>
                                        <li>“These days, I only go to the mechanic if I try and can neither find nor fix the problem myself.” </li>
                                    </ul>
                                </div>
                            </div>
                        </ul>
                    </div>
                    <div className='py-10 flex flex-col md:flex-row gap-5 md:w-[75%]'>
                        <span className='realisation-tag'>
                            <img src="/assets/arrow-diagonal.png" alt="" />
                            <p>Confidence Building</p>
                        </span>
                        <span className='realisation-tag'>
                            <img src="/assets/arrow-diagonal.png" alt="" />
                            <p>Automotive Learning Application</p>
                        </span>
                        <span className='realisation-tag'>
                            <img src="/assets/arrow-diagonal.png" alt="" />
                            <p>Maintenance Practice Improvement</p>
                        </span>
                        <span className='realisation-tag'>
                            <img src="/assets/arrow-diagonal.png" alt="" />
                            <p>Visually Engaging Concepts</p>
                        </span>
                    </div>
                </div>
                <div className='my-10'>
                    <span className='block text-xl md:text-[28px] font-bold my-5'>Personas</span>
                    <p className='py-8'>We organized and analyzed the data collected from the interviews, resulting in the creation of personas and scenarios that accurately represent our target users: students, car owners, interns, and engineers.</p>
                    <div className='flex flex-col py-8 gap-8'>
                        <LazyLoadImage 
                            src='/assets/work/forwheels/joey-c.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                        <LazyLoadImage 
                            src='/assets/work/forwheels/francesca-g.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                        <LazyLoadImage 
                            src='/assets/work/forwheels/robert-k.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                        <LazyLoadImage 
                            src='/assets/work/forwheels/ella-w.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                    </div>
                </div>
                <div>
                    <span className='block text-xl font-bold my-5'>How might we?</span>
                    <p className='text-justify my-6'>Delving into user personas and scenarios shed light on key pain points, sparking the pivotal question: How might we transform these challenges and user frustrations into actionable solutions? This inquiry marks the first step towards innovation, guiding us to think about possible solutions.</p>
                    <LazyLoadImage 
                        src='/assets/work/forwheels/challenge-solution-table.png'
                        className='w-full h-full py-10'
                        effect='blur'
                        alt='alt'
                    />
                </div>
            </div>
            <div id='information-architecture'  className='min-h-[50vh]'>
                <h3 className='work-subhead'>information architecture</h3>
                <p className='text-justify py-6'>After this, the application's functions was organised into four categories: learn mode, maintenance mode, freestyle mode, and challenge mode. Also a hierarchical navigation system was developed to make it easier to explore these features.</p>
                <div className='space-y-8 py-6'>
                    <LazyLoadImage 
                        src='/assets/work/forwheels/info-architecture-1.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/forwheels/info-architecture-2.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/forwheels/info-architecture-3.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/forwheels/info-architecture-4.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/forwheels/info-architecture-5.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                </div>
            </div>
            <div id='sketches'  className='min-h-[50vh]'>
                <h3 className='work-subhead'>Sketches</h3>
                <p className='text-justify my-4'>After mapping out the user journey and structuring the information architecture, we dove into the sketching phase, focusing on bringing ForWheels' key features to life through visual design. ForWheels isn't just an app; it's a revolution in automotive learning, making complex concepts accessible and engaging with augmented reality. </p>
                <p className='text-justify my-4'>Our approach included immersive simulations, personalized learning paths, a point-based system for motivation, and interactive quizzes to solidify knowledge. Through rapid iteration of our wireframes, we fine-tuned our design to ensure an intuitive, enriching experience that boosts confidence and skill in automobile maintenance.</p>
                <div className='py-10'>
                    <LazyLoadImage 
                        src='/assets/work/forwheels/sketches-1.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/forwheels/sketches-2.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                </div>
            </div>
            <div id='style-components'>
                <h3 className='work-subhead'>Style components</h3>
                <p>Throughout the design process, carefully evaluated different aspects of the user interface, including spacing, typography, and color choices. This was done in order to ensure flexibility, which allows all the elements to function seamlessly together and maintain its functionality across a range of device resolutions.</p>
                <div className='my-8 bg-[#EEEEEE] pt-5 px-8'>
                    <p className='my-10'>Design System_Style Guide</p>
                    <div className='flex flex-col md:flex-row gap-4'>
                        <LazyLoadImage 
                            src='/assets/work/forwheels/style-components-1.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                        <LazyLoadImage 
                            src='/assets/work/forwheels/style-components-2.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                    </div>
                </div>
            </div>
            <div id='high-fidelity'>
                <h3 className='work-subhead'>High-fidelity design</h3>
                <div className='space-y-8 py-8'>
                    <LazyLoadImage 
                        src='/assets/work/forwheels/high-fidelity-1.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/forwheels/high-fidelity-2.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/forwheels/high-fidelity-3.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/forwheels/high-fidelity-4.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/forwheels/high-fidelity-5.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/forwheels/high-fidelity-6.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/forwheels/high-fidelity-7.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                </div>
            </div>
            <div id='user-testing' className='flex flex-col gap-5 mb-2 text-[#515151]'>
                <h3 className='work-subhead text-black'>User testing</h3>
                <p className='text-justify'>We engaged twelve individuals aged 20-45, including students and vehicle owners of various expertise levels, to test an augmented reality solution. They performed tasks while verbalizing their thoughts using the "think aloud" method, supplemented by interviews and the System Usability Scale (SUS) for deeper insights.</p>
                <p className='text-justify'>The think-aloud protocol highlighted effective features and areas for improvement. Insights into users' emotional responses to the ForWheels app were uncovered through detailed questioning.</p>
                <p className='text-justify'>Feedback from these sessions led to a reassessment of specific user frustrations, aiming to refine the app for those facing the greatest challenges.</p>
                <p className='text-justify'>Initial tests were biased due to administrator assistance and were excluded from the final analysis. The misuse of technical jargon was corrected for future interactions.</p>
                <p className='text-justify'>Based on the test outcomes, we've developed recommendations to enhance the app's user-friendliness and accessibility.</p>
            </div>
            <div id='iterations'>
                <h3 className='work-subhead'>Iterations</h3>
                <p className='text-justify my-4 py-2'>We assessed all the feedback we got from the users and grouped them based on the constraints we’re currently faced with. With time at the top of that list, it was easier to prioritize and decide what our next steps should be.</p>
                <div className='space-y-8 py-8'>
                    <LazyLoadImage 
                        src='/assets/work/forwheels/iterations-1.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/forwheels/iterations-2.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/forwheels/iterations-3.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                </div>
            </div>
            <div id='limitations' className='min-h-[30vh] flex flex-col justify-center'>
                 <h3 className='work-subhead'>Limitations and future considerations</h3>
                 <p className='text-justify my-4'>The application's potential is currently hindered by several limitations, including the lack of a feature for collaborative AR over long distances and its heavy reliance on a consistent internet connection. Additionally, the hardware, specifically the Hololens2, presents challenges with its poor battery life and tendency to overheat with extended use.</p>
                <p className='text-justify my-4'>Despite these issues, the app aims to enhance user experience by offering services such as connecting to local garages for assistance, vehicle repair diagnostics, and premium accounts that offer extra services like home delivery of car parts, hinting at future expansions possibly including features for planes.</p>
            </div>    
        </div>
    </WorkLayout>
  )
}

export default ForWheels