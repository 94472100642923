import React from 'react'
import WorkLayout from '../../components/Work/Layout'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const PageNav = [
    {
        id: 1,
        title: 'Overview',
        path: 'overview'
    },
    {
        id: 2,
        title: 'My goals',
        path: 'my-goals'
    },
    {
        id: 3,
        title: 'Design Process',
        path: 'design-process'
    },
    {
        id: 4,
        title: 'Qualitative Research',
        path: 'qualitative-research'
    },
    {
        id: 8,
        title: 'High fidelity',
        path: 'high-fidelity'
    }, 
    {
        id: 9,
        title: 'Style components',
        path: 'style-components'
    }
]

const Buddie = () => {
  return (
    <WorkLayout PageNav={PageNav} coverImg='/assets/work/Budi.png'>
         <div 
            className='md:max-w-[70%] py-5 md:py-10 flex flex-col justify-between gap-5 min-h-screen'
        >
            <div id='overview' className=''>
                <h1 className="work-subhead">Overview</h1>
                <div className='flex flex-col gap-3 md:gap-7'>
                    <p>Whenever people get less busy, they mostly attend events. It’s a way to unwind after such a tiring week and most importantly it’s a way to create the best possible experience. Going for events alone might be discouraging and it ends up with people forgoing the opportunity. 
                    Budi is an app that links one or more persons going for similar events. The best way to create experience is by sharing with people. </p>
                    <p>Budi is an app that links one or more persons going for similar events. The best way to create experience is by sharing with people. </p>
                </div>
            </div>
            <div id='my-goals' className='mt-5'>
                <h1 className="work-subhead">My goals</h1>
                <p className='mb-12'>When some people get less busy, they prefer to attend events It's a great way to unwind after such a tiring week, and it's also the best way to create the best possible experience. However, attending events alone might be discouraging, causing people to pass up the opportunity altogether.</p>
                <div className='bg-black text-white py-10 px-8'>
                    <div>
                        <h2 className='font-semibold text-xl pb-5'>Design Process</h2>
                        <div className='grid grid-cols-2 gap-3 md:flex md:justify-between items-start'>
                            <div className='flex flex-col gap-5'>
                                <bold className='flex items-center gap-2'><span className='h-2 w-2 rounded-full bg-white block'></span>UX</bold>
                                <ul className='flex flex-col gap-2'>
                                    <li>User interview</li>
                                    <li>Journey map</li>
                                    <li>How might we</li>
                                    <li>Crazy 8s</li>
                                    <li>User Persona</li>
                                    <li>Empathy map</li>
                                    <li>User flow</li>
                                </ul>
                            </div>
                            <div className='flex flex-col gap-5'>
                                <bold className='flex items-center gap-2'><span className='h-2 w-2 rounded-full bg-white block'></span>Prototyping</bold>
                                <ul>
                                    <li>App structure</li>
                                    <li>Wireframes</li>
                                    <li>Interactive prototype</li>
                                </ul>
                            </div>
                            <div className='flex flex-col gap-5'>
                                <bold className='flex items-center gap-2'><span className='h-2 w-2 rounded-full bg-white block'></span>UI</bold>
                                <ul>
                                    <li>App icon</li>
                                    <li>Style guide</li>
                                    <li>Components</li>
                                    <li>High fidelity</li>
                                    <li>Landing page</li>
                                </ul>
                            </div>
                            <div className='flex flex-col gap-5'>
                                <bold className='flex items-center gap-2'><span className='h-2 w-2 rounded-full bg-white block'></span>Testing</bold>
                                <ul>
                                    <li>User testing</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <div id='design-process' className=''>
                <h1 className="work-subhead font-dmsans">Design Process</h1>
                <p> In the design process, key terms include discover, define, develop, and deliver which are not always straightforward, as you sometimes have to iterate and refine them in between stages.</p>
                <div>
                    <LazyLoadImage 
                        src='/assets/work/budi/design-process.png'
                        className='w-full h-full my-14'
                        effect='blur'
                        alt='alt'
                    />
                </div>
                <div>
                    <div>
                        <h2 className='font-semibold flex items-center gap-2 text-xl md:text-3xl py-8'><span className='h-2 w-2 md:h-4 md:w-4 rounded-full bg-black block'></span>Research</h2>
                        <p className='text-sm md:text-base'>My method for this project was to conduct user interviews with five individuals who met the criteria (purchased tickets online, attended events alone, or attended with others) The method gave me a lot of insights into how events work, the frustrations ,people's experience  and more.</p>
                    </div>
                    <div>
                        <h2 className='font-semibold flex items-center gap-2 text-xl md:text-3xl py-8'><span className='h-2 w-2 md:h-4 md:w-4 rounded-full bg-black block'></span>Define</h2>
                        <p className='text-sm md:text-base'>After analyzing all the information I gained from the interview, I factored out my goals and objectives for this project. I know who I am designing for (target audience) and i started generating ideas.</p>
                    </div>
                    <div>
                        <h2 className='font-semibold flex items-center gap-2 text-xl md:text-3xl py-8'><span className='h-2 w-2 md:h-4 md:w-4 rounded-full bg-black block'></span>Ideation</h2>
                        <p className='text-sm md:text-base'>I did empathy mapping, user journey map , how might we’s ,information architecture, competitive analysis, user flow, story board,mood board . Utilized these methods to gain insights into user painpoints and to gain a deeper understanding of their needs.</p>
                    </div>
                    <div>
                        <h2 className='font-semibold flex items-center gap-2 text-xl md:text-3xl py-8'><span className='h-2 w-2 md:h-4 md:w-4 rounded-full bg-black block'></span>Design</h2>
                        <p className='text-sm md:text-base'>A slick interface with aesthetically pleasing interfaces and a seamless user experience, easy to navigate, my objectives were to solve problems using design Well thought out design with aesthetic interfaces and a seamless experience, easy to navigate Putting empathy for the user as the key ingredient</p>
                    </div>
                </div>
                
            </div>
            <div id='qualitative-research'  className=''>
                <h1 className="work-subhead">Qualitative Research</h1>
                <div>
                    <LazyLoadImage 
                        src='/assets/work/budi/q-research.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                </div>
                <div>
                    <p className='my-10'>User interviews are a qualitative research tool which serves as a means of extracting information from users to understand them better and also for ideation. For this project i interviewed five people within the age group 18-35 who met the criteria (buys ticket online, have attended events alone, also have attended with people).Highlighting few which includes :</p>
                    <ul className='flex flex-col gap-4'>
                        <li className='flex gap-5 items-center'>
                            <span className='md:h-4 md:w-4 h-2 w-2 rounded-full bg-black block'></span>
                            <p>Young professionals who enjoys going out at whatever chance they get to unwind</p>
                        </li>
                        <li className='flex gap-5 items-center'>
                            <span className='md:h-4 md:w-4 h-2 w-2 rounded-full bg-black block'></span>
                            <p>New people in a location who are looking  to go for events and try new things</p>
                        </li>
                    </ul>
                </div>
                <div className='mt-20'>
                    <h2 className='mini-head text-[28px]'>Competitive analysis</h2>
                    <p>I analyzed few applications relating to Budi, Mapped out few things concerning the applications, the screens were analyzed during the process checking out detailed reasons to why some functions are there and looking for ways it can be better, examples of the application includes Eventbrite and whova.</p>
                    <div>
                        <LazyLoadImage 
                            src='/assets/work/budi/comp-analysis.png'
                            className='w-full h-full my-10'
                            effect='blur'
                            alt='alt'
                        />
                    </div>
                </div>
                <div>
                    <h2 className='mini-head text-[28px]'>User Persona</h2>
                    <div className='flex flex-col md:flex-row justify-between my-10'>
                        <div className='md:w-[30%] flex flex-col gap-10 justify-between'>
                            <div className='card md:h-[45%]'>
                                <div className='flex items-center'>
                                    <img src='/assets/work/budi/michael-joseph.png' alt='avatar' />
                                    <div className='text-sm'>
                                        <p>Michael Joseph</p>
                                        <p>Education: Masters</p>
                                        <p>Status: single</p>
                                        <p>Age: 28 years</p>
                                    </div>
                                </div>
                                <p className='text-[#005B5C] md:text-lg font-medium my-3'>“Moments like this needs to be shared,going alone is just not an option.”</p>
                            </div>
                            <div className="card h-[45%]">
                                <p className='bg-[#389394] text-white p-4'>Interest</p>
                                <ul className='bg-[#CCDFDF] list-disc px-8 p-4'>
                                    <li>I enjoy regular hangouts with friends.</li>
                                    <li>I enjoy regular hangouts with friends.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='md:w-[65%] flex flex-col gap-5 md:gap-10 justify-between'>
                            <div className="card mt-5 md:mt-0 md:h-[45%]">
                                <p className='bg-[#34C759] text-white p-4'>Bio</p>
                                <p className='bg-[#D4EAD7] p-4'>" I enjoy going out for shows and other events, it’s a way to unwind after a long week.I often purchase my tickets online and i buy two or more tickets sometimes when i do not want to go for the shows alone,i contact my friends ,give them the tickets and we link up for the show.The main issue i sometimes experience is the transporting or accomodation because most events occurs at night and getting back home alone is often risky."</p>
                            </div>
                            <div className="card md:h-[45%]">
                                <p className='bg-[#C62121] text-white p-4'>Pain Points/Frustrations</p>
                                <ul className='bg-[#A0575740]/25 list-disc p-4 px-8'>
                                    <li>I end up overspending because i do not want to attend events alone.</li>
                                    <li>Fear of purchasing tickets online and having difficulty at the venue.</li>
                                    <li>I do not think going to the venue alone is safe</li>
                                    <li>Difficulty in getting back home or transporting.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='my-12'>
                    <h2 className='mini-head text-[28px]'>User Flows</h2>
                    <p className='text-sm md:text-base'>I created an end to end workflow for the mobile interface . The visuals below shows the seamless workflow from one flow to the next across different tasks and screen in one app.</p>
                    <div>
                        <LazyLoadImage 
                            src='/assets/work/budi/user-flows.png'
                            className='w-full h-full py-16'
                            effect='blur'
                            alt='alt'
                        />
                    </div>
                </div>
                
            </div>
            <div id='high-fidelity' className=''>
                <h1 className="work-subhead">High Fidelity</h1>
                <div>
                    <h3 className='mini-head text-xl md:text-[28px]'>Onboarding screens</h3>
                    <p className='text-sm md:text-base'>I made the onboarding screen quite interesting , simple and it gives some hints concerning what the app is about.</p>
                    <div className='my-6 bg-[#F4F4F6] py-10 px-8'>
                        <ul className='grid grid-cols-2 gap-2 md:flex justify-between'>
                            {[1,2,3,4].map((item) => (
                                <LazyLoadImage
                                    key={item} 
                                    src={`/assets/work/budi/onboarding-${item}.png`}
                                    className='w-full h-full'
                                    effect='blur'
                                    alt='alt'
                                />
                            ))}
                        </ul>
                    </div>
                </div>

                <div>
                    <h3 className='mini-head text-xl md:text-[28px]'>Create account</h3>
                    <p className='text-sm md:text-base'>Creating an account process is also straightforward, few details are needed to create an account.Registerations gives new users full access to the app. If they already have an account they can proceed to log in.</p>
                    <div className='my-6 bg-[#F4F4F6] py-10 px-8'>
                        <ul className='grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4'>
                            {Array.from(Array(8)).map((item, index) => (
                                <LazyLoadImage
                                    key={index} 
                                    src={`/assets/work/budi/create-account-${index + 1}.png`}
                                    className='w-fit h-fit'
                                    effect='blur'
                                    alt='alt'
                                />
                            ))}
                        </ul>
                    </div>
                </div>

                <div>
                    <h3 className='mini-head text-xl md:text-[28px]'>Create account using google</h3>
                    <p className='text-sm md:text-base'>You can also create your account or login using google directly.</p>
                    <div className='my-6 bg-[#F4F4F6] py-10 px-8'>
                        <ul className='grid grid-cols-2 gap-2 md:flex justify-between'>
                            {[1,2,3,4].map((item, index) => (
                                <LazyLoadImage
                                    key={index} 
                                    src={`/assets/work/budi/create-account-google-${item}.png`}
                                    className='w-full h-full'
                                    effect='blur'
                                    alt='alt'
                                />
                            ))}
                        </ul>
                    </div>
                </div>

                <div>
                    <h3 className='mini-head text-xl md:text-[28px]'>Select Category, Upload picture and Location</h3>
                    <p className='text-sm md:text-base'>After the account creation process, the next stage is to select a location ,few categories and upload an image.</p>
                    <div className='my-6 bg-[#F4F4F6] py-10 px-8'>
                        <ul className='grid grid-cols-2 gap-2 md:flex justify-between'>
                            {[1,2,3,4].map((item, index) => (
                                <LazyLoadImage
                                    key={index} 
                                    src={`/assets/work/budi/update-bio-${item}.png`}
                                    className='w-full h-full'
                                    effect='blur'
                                    alt='alt'
                                />
                            ))}
                        </ul>
                    </div>
                </div>

                <div>
                    <h3 className='mini-head text-xl md:text-[28px]'>Main screens</h3>
                    <p className='text-sm md:text-base'>The platform has been designed to be user-friendly, allowing you to select your event, view details, buy tickets and pay quickly and then meet other people going to the same event. You can message or call them at no cost. It has such beautiful interface and navigating is easy.</p>
                    <div className='my-6 bg-[#F4F4F6] py-10 px-8'>
                        <ul className='grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-5'>
                            {Array.from(Array(23)).map((item, index) => (
                                <LazyLoadImage
                                    key={index} 
                                    src={`/assets/work/budi/main-screens/main-screen-${index + 1}.png`}
                                    className='w-fit h-fit'
                                    effect='blur'
                                    alt='alt'
                                />
                            ))}
                        </ul>
                    </div>
                </div>
                
            </div>
            <div id='style-components'>
               <h1 className="work-subhead">Style Components</h1>
                <div className='min-h-full'>
                    <LazyLoadImage                  
                        src='/assets/work/budi/style-components.png'
                        className='w-full h-full'
                        effect='blur'
                         alt='alt'
                    />
                </div>
            </div>
             
        </div>
    </WorkLayout>
  )
}

export default Buddie