import React from 'react'
import { NavLink } from 'react-router-dom'
import { projectsData } from '../../data/Projects'

const WorkNavbar = ({ nextPage, prevPage }) => {
  return (
    <div className='pt-5 md:py-10 sm:px-0'>
        <div className='w-[95vw] sm:w-[90vw] overflow-hidden flex items-center'>
          <nav className='no-scrollbar w-full flex lg:flex-wrap gap-20 overflow-x-scroll lg:overflow-auto'>
            {projectsData.map((project) => (
              <NavLink 
              className={({ isActive }) => isActive ? `worknav-${project.id} cursor-pointer text-base sm:text-xl font-bold min-w-fit` : `min-w-fit hidden md:block font-semibold text-base sm:text-xl cursor-pointer text-[#475467]`} 
              to={`/work/${project.name.split(" ").join("").toLowerCase()}`} 
              key={project.id}
              >
                0{project.id}/ {project.name}
              </NavLink>
            ))}
          </nav>
          <div className='md:hidden flex gap-3 items-end'>
            <button onClick={prevPage} className="border border-black rounded-full p-3 ">
              <svg className="w-3 sm:w-5 h-3 sm:h-5" viewBox="0 0 532 532">
                <path
                  fill="#000"
                  d="M355.66 11.354c13.793-13.805 36.208-13.805 50.001 0 13.785 13.804 13.785 36.238 0 50.034L201.22 266l204.442 204.61c13.785 13.805 13.785 36.239 0 50.044-13.793 13.796-36.208 13.796-50.002 0a5994246.277 5994246.277 0 0 0-229.332-229.454 35.065 35.065 0 0 1-10.326-25.126c0-9.2 3.393-18.26 10.326-25.2C172.192 194.973 332.731 34.31 355.66 11.354Z"
                />
              </svg>
            </button>
            <button onClick={nextPage} className="border border-black rounded-full p-3 ">
              <svg className="w-3 h-3 sm:w-5 sm:h-5" viewBox="0 0 532 532">
                <path
                  fill="#000"
                  d="M176.34 520.646c-13.793 13.805-36.208 13.805-50.001 0-13.785-13.804-13.785-36.238 0-50.034L330.78 266 126.34 61.391c-13.785-13.805-13.785-36.239 0-50.044 13.793-13.796 36.208-13.796 50.002 0 22.928 22.947 206.395 206.507 229.332 229.454a35.065 35.065 0 0 1 10.326 25.126c0 9.2-3.393 18.26-10.326 25.2-45.865 45.901-206.404 206.564-229.332 229.52Z"
                />
              </svg>
            </button>
          </div>
        </div>
    </div>
  )
}

export default WorkNavbar