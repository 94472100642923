import React from 'react'
import WorkLayout from '../../components/Work/Layout'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Connector from '../../components/Icons/Connector'

//make banner image instant load instead of lazyload

const PageNav = [
    {
        id: 1,
        title: 'The problem',
        path: 'the-problem'
    },
    {
        id: 2,
        title: 'Competitive analysis',
        path: 'competitive-analysis'
    },
    {
        id: 3,
        title: 'Proposed solution',
        path: 'proposed-solution'
    },
    {
        id: 4,
        title: 'User Research',
        path: 'user-research'
    },
    {
        id: 5,
        title: 'Key realisations',
        path: 'key-realisations'
    }, 
    {
        id: 6, 
        title: 'Personas and HMW',
        path: 'personas'
    },
    {
        id: 7,
        title: 'Scenarios and task analysis',
        path: 'scenarios'
    },
    {
        id: 8,
        title: 'Information architecture',
        path: 'info-architecture'
    }, 
    {
        id: 9,
        title: 'Sketches, wireframe & high fidelity',
        path: 'sketches'
    }, 
    {
        id: 10,
        title: 'Usability testing', 
        path: 'usability-testing'
    }, 
    {
        id: 11,
        title: 'Future enhancements',
        path: 'future-enhancements'
    }
]

const CarbsBuddy = () => {
  return (
    <WorkLayout PageNav={PageNav} coverImg='/assets/work/carbsbuddy.png'>
         <div 
            className='md:max-w-[70%] py-5 md:py-10 flex flex-col justify-between gap-5 min-h-screen'
        >
            <div id='the-problem' className='my-5'>
                <h3 className='font-semibold my-3 text-2xl'>The Problem</h3>
                <p>
                Research has shown that <span className='font-bold text-[#036358]'>unhealthy eating habits</span> is a major contributor to the development of diabetes. Individuals with diabetes struggle to adopt <bold>healthy eating habits</bold> due to a lack of awareness, motivation, and support. This can lead to <bold>complications</bold> and <bold>worsen health outcomes.</bold> A solution is needed to help individuals with diabetes <bold>adopt healthy eating habits</bold>, improve their quality of life, and <bold>reduce the burden of diabetes-related complications.</bold>
                </p>
            </div>
            <div id='competitive-analysis'  className=''>
                <h1 className="work-subhead">Competitive analysis</h1>
                 <p>
                 The initial step following the identification of the problem was to conduct a comprehensive competitive analysis of various applications. This examination included both direct and indirect competitors, aiming to gain a deeper understanding of the landscape and to pinpoint areas where our application could distinguish itself. This strategic analysis was pivotal in shaping our approach, enabling us to identify unique opportunities for innovation and to ensure our solution not only meets but exceeds user expectations in a crowded market.
                 </p>
                 <div className='flex justify-between gap-8 my-8'>
                    <LazyLoadImage 
                        src='/assets/work/carbsbuddy/competitive-analysis-1.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/carbsbuddy/competitive-analysis-2.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                 </div>
                 <p>
                 Following the competitive analysis, we meticulously outlined the strengths and weaknesses of these applications. This evaluation was crucial in our competitive analysis, as it allowed us to discern what aspects were working well and which ones fell short, providing a clear direction for innovation and improvement in our own application. This comprehensive assessment of positive and negative features served as a foundational step in developing a strategy that leverages the strengths of competitors while addressing their limitations.
                 </p>
                 <div className='flex flex-col gap-8 my-10'>
                    <div className='flex flex-col md:flex-row gap-2'>
                        <div className="w-full">
                            <LazyLoadImage 
                                src='/assets/work/carbsbuddy/yazio.png'
                                className='w-full h-full'
                                effect='blur'
                                alt='alt'
                            />
                        </div>
                        <div className='flex flex-col gap-4'>
                            <div className='flex flex-col gap-2'>
                                <h4 className='bg-[#03796E] pos-nev'>Positives</h4>
                                <div className='pos-nev-deets'>
                                    <ul className='list-disc px-5 text-sm'>
                                        <li>The app's user-friendly design simplifies the process of logging food intake</li>
                                        <li>The app tracks macronutrients (protein, fat, and carbohydrates) in addition to calories, which can help users maintain a balanced diet.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <h4 className='bg-[#D22F27] pos-nev'>Negatives</h4>
                                <div className='pos-nev-deets'>
                                    <ul className='list-disc px-5 text-sm'>
                                        <li>
                                        The app does not cater for the needs of people with specific health conditions such as diabetes
                                        </li>
                                        <li>There is no feature for people to learn more about nutrition and how it affects their health</li>
                                        <li>The application does not have a feature for people to connect with others who are also trying to maintain a healthy lifestyle, i.e a community feature </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row gap-8'>
                        <div className="w-full">
                            <LazyLoadImage 
                                src='/assets/work/carbsbuddy/carbs-cal.png'
                                className='w-full h-full'
                                effect='blur'
                                alt='alt'
                            />
                        </div>
                        <div className='flex flex-col gap-4'>
                            <div className='flex flex-col gap-2'>
                                <h4 className='bg-[#03796E] pos-nev'>Positives</h4>
                                <div className='pos-nev-deets'>
                                    <ul className='list-disc px-5 text-sm'>
                                        <li>The app uses visual aids, such as photos of food and portion sizes, to make it easier for users to estimate their food intake and track their calories and macronutrients.</li>
                                        <li>Users can set their own goals and preferences for the app, such as calorie intake and macronutrient ratios.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <h4 className='bg-[#D22F27] pos-nev'>Negatives</h4>
                                <div className='pos-nev-deets'>
                                    <ul className='list-disc px-5 text-sm'>
                                        <li>
                                        Users need to manually log their food intake, which can be time-consuming.
                                        </li>
                                        <li>The Application is only focused on nutrient tracking which may limit its use case for individuals who may have other needs related to food and nutrition</li>
                                        <li>The app does not provide an analysis of your nutrition records for you to have proper insight on what kind of food you have been consuming</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
            </div>
            <div id='proposed-solution'>
               <h1 className="work-subhead">Proposed solution</h1>
               <LazyLoadImage 
                    src='/assets/work/carbsbuddy/hands-chart-cover.png'
                    className='w-full h-full'
                    effect='blur'
                    alt='alt'
                />
                <p>The primary objective of the project is to develop a comprehensive diabetes management solution that is specifically designed to address the needs of individuals with diabetes. The solution will focus on providing support to diabetic individuals by helping them improve their eating habits and maintain a healthy lifestyle.The ultimate goal of the project is to create a diabetes management solution that is easy to use, effective, and accessible to a wide range of individuals with diabetes.</p>
                <div className='mt-10'>
                    <h3 className='my-8 font-bold text-2xl'>Key features</h3>
                    <p>Here are the key realisations and takeaways from the interviews and individuals that was spoken to, which was then categorised into several groups, which include:</p>
                    <div className='mt-12 w-full'>
                        <div className='table-item'>
                            <h3>01. Log BGL</h3>
                            <p>Carbs Buddy allows  users to log their blood sugar levels using their glucose meter. By doing this, they can monitor their blood sugar levels on a daily basis and gain a better understanding of how their nutrition affects the frequency of their blood sugar levels.</p>
                        </div>
                        <div className='table-item'>
                            <h3>02. Meal Tracking</h3>
                            <p>The meal tracking feature allows users to track their daily nutrition intake with the meal tracking feature, which helps them to have a better insight as to what particular food they should consume and avoid to achieve a better eating habit and health goals</p>
                        </div>
                        <div className='table-item'>
                            <h3>03. Meal Plan</h3>
                            <p>This feature creates a series of meal plans tailored to the users bio data and health information, it helps you maintain a structured and healthy eating habit and also to know the particular food to consume for a period of time.</p>
                        </div>
                        <div className='table-item'>
                            <h3>04. Community</h3>
                            <p>With this feature, users can update their progress and engage with other individuals who have diabetes. They can discuss various topics related to nutrition, mental health, medication, and wellness, allowing for a supportive community and a more comprehensive approach to diabetes management.</p>
                        </div>
                    </div>
                </div>
            </div>
             <div id='user-research'  className='min-h-[50vh]'>
                <h1 className="work-subhead">User Research</h1>
                <LazyLoadImage 
                    src='/assets/work/carbsbuddy/hands-chart-cover.png'
                    className='w-full h-full'
                    effect='blur'
                    alt='alt'
                />
                <div className='text-sm sm:text-base md:text-lg'>
                    <p>We conducted <bold>interviews, empathy maps,</bold> and <bold>surveys</bold> to <bold>identify the behaviour</bold> and needs of people who want to manage their blood glucose level ang health condition. This group of people is our primary audience.</p>
                    <p>Through our extensive user research, we were able to pinpoint the primary obstacles that individuals with diabetes encounter when attempting to manage their condition. Users experienced challenges in maintaining a healthy diet and managing their blood glucose levels, which included difficulties in planning meals and making food choices, remembering to take their medication, and a lack of sufficient support.</p>
                    <p>In order to understand our user better, we carried surveys with aims to understand the needs, wants, and expectations of users through their experiences and emotions. Our user research encompassed a combination of online and offline surveys, which involved 13 individuals responding to a total of 11 questions. The following report outlines the results of this research.</p>
                </div>
                <div className='flex gap-5 md:gap-10 mt-12'>
                    <div className='flex flex-col gap-10'>
                        <LazyLoadImage 
                            src='/assets/work/carbsbuddy/user-research-1.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                        <LazyLoadImage 
                            src='/assets/work/carbsbuddy/user-research-2.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                        <LazyLoadImage 
                            src='/assets/work/carbsbuddy/user-research-3.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                    </div>
                    <div className='flex flex-col gap-10'>
                        <LazyLoadImage 
                            src='/assets/work/carbsbuddy/user-research-4.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                        <LazyLoadImage 
                            src='/assets/work/carbsbuddy/user-research-5.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                    </div>
                </div>
                <div className='mt-16 flex flex-col gap-12'>
                    <div className='flex flex-col md:flex-row gap-5 md:gap-24'>
                        <h3 className='font-bold text-lg'>Major Challenges</h3>
                        <div>
                            <p>Major challenges were the inability to:</p>
                            <ul className='list-disc px-4 font-semibold text-black'>
                                <li>effectively navigate meal planning,</li>
                                <li>medictaion adherence,</li>
                                <li>reminders,</li>
                                <li>and find a supportive community.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row gap-5 md:gap-24'>
                        <h3 className='font-bold'>Age group</h3>
                        <div className='flex flex-wrap gap-6'>
                            <span className='realisation-tag'>
                                <img src="/assets/arrow-diagonal.png" alt="" />
                                <p>46 - 60 (30.8%)</p>
                            </span>
                            <span className='realisation-tag'>
                                <img src="/assets/arrow-diagonal.png" alt="" />
                                <p>36-45 (23%)</p>
                            </span>
                            <span className='realisation-tag'>
                                <img src="/assets/arrow-diagonal.png" alt="" />
                                <p>18-25 (23%)</p>
                            </span>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row gap-5 md:gap-24'>
                        <h3 className='font-bold'>Diabetes type</h3>
                        <div className='flex gap-6 flex-wrap'>
                            <span className='realisation-tag'>
                                <img src="/assets/arrow-diagonal.png" alt="" />
                                <p>Type 2 (46%)</p>
                            </span>
                            <span className='realisation-tag'>
                                <img src="/assets/arrow-diagonal.png" alt="" />
                                <p>Type 1 (38%)</p>
                            </span>
                        </div>
                    </div>
                </div>   
            </div>
            <div id='key-realisations'  className=''>
                <h1 className="work-subhead">Key Realisations</h1>
                <p>Here are the key realisations and takeaways from the interviews and individuals that was spoken to, which was then categorised into several groups, which include:</p>
                <div className='mt-12'>
                    <div className='table-item'>
                        <h3>01. Inaccurate food monitoring</h3>
                        <p>It is not easy to accurately keep track of the amount of food consumed and the right time to consume it.</p>
                    </div>
                    <div className='table-item'>
                        <h3>02. Reminder</h3>
                        <p>Most diabetic patients miss medication prescriptions & diet recommendations.</p>
                    </div>
                    <div className='table-item'>
                        <h3>03. Lack of time and motivation</h3>
                        <p>Except for the elder ones, many people have busy schedules and find it difficult to make out time to prepare healthy meals.When there are no metrics to measure progress or keep track of performance.</p>
                    </div>
                    <div className='table-item'>
                        <h3>04. No nutritional knowledge</h3>
                        <p>Not knowing the right food to consume at the right time and the amount of food to consume.</p>
                    </div>
                </div>
            </div>
            <div id='empathy-mapping'  className='min-h-[50vh]'>
                <h1 className="work-subhead">Empathy mapping</h1>
                <p className='mb-10'>Here are the key realisations and takeaways from the interviews and individuals that was spoken to, which was then categorised into several groups, which include:</p>
                <LazyLoadImage 
                    src='/assets/work/carbsbuddy/empathy-mapping.png'
                    className='w-full h-full'
                    effect='blur'
                    alt='alt'
                />
            </div>
            <div id='key-problems'>
                <h1 className="work-subhead">Key Problems</h1>
                <p className='mb-10'>Here are the key realisations and takeaways from the interviews and individuals that was spoken to, which was then categorised into several groups, which include:</p>
                <div className='flex gap-3 flex-wrap'>
                    <LazyLoadImage 
                        src='/assets/work/carbsbuddy/key-problems-1.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/carbsbuddy/key-problems-2.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/carbsbuddy/key-problems-3.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/carbsbuddy/key-problems-4.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/carbsbuddy/key-problems-5.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/carbsbuddy/key-problems-6.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                </div>
            </div>
            <div id='personas'>
                <h1 className="work-subhead">Personas</h1>
                <p className='mb-10'>Collating and sorting the data we gathered from our interviews, we created personas and scenarios to best represent our target users;  Students, car owners, interns and engineers.</p>
                <div className='flex flex-col gap-8'>
                    <div className='persona-card'>
                        <div className="w-full">
                            <LazyLoadImage 
                                src='/assets/work/carbsbuddy/mary-grace.png'
                                className='w-full h-full'
                                effect='blur'
                                alt='alt'
                            />
                        </div>
                        <div className='flex flex-col gap-5'>
                            <div className='item flex flex-wrap gap-3 rounded-r-3xl py-2 px-3'>
                                <p><span>Age:</span> 27 years old</p>
                                <p><span>Occupation:</span> Banker</p>
                                <p><span>Location:</span> Birmingham, UK</p>
                                <p><span>Status:</span> Married</p>
                                <p><span>Diabetes type:</span> Type 2</p>
                                <p><span>Impairment:</span> Diabetic Retinopaty</p>
                            </div>
                            <div className='item rounded-tr-3xl rounded-bl-3xl'>
                                <p className='text-[#036358] px-6 font-semibold text-base'>Goals</p>
                                <ul className='list-disc px-6 py-3'>
                                    <li>She wants to maintain healthy living through proper nutrition</li>
                                    <li>To manage her blood glucose level and overall health condition to avoid long term health complications.</li>
                                </ul>
                            </div>
                            <div className='item rounded-tl-3xl rounded-br-3xl'>
                                <p className='text-[#036358] px-6 font-semibold text-base'>Pain Points</p>
                                <ul className='list-disc px-6 py-3'>
                                    <li>She works long hours, so she doesn’t have enough time to keep track of her health.</li>
                                    <li>Uncertainty of what to eat to stabilize her blood glucose levels because her BGL usually drops at night.</li>
                                    <li>I struggle to read text in applications due to my condition of Diabetic retinopathy.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='persona-card'>
                        <div className="w-full">
                            <LazyLoadImage 
                                src='/assets/work/carbsbuddy/charles-davis.png'
                                className='w-full h-full'
                                effect='blur'
                                alt='alt'
                            />
                        </div>
                        <div className='flex flex-col gap-5'>
                            <div className='item flex flex-wrap gap-3 rounded-r-3xl py-2 px-3'>
                                <p><span>Age:</span> 42 years old</p>
                                <p><span>Occupation:</span> Data Analyst</p>
                                <p><span>Location:</span> Birmingham, UK</p>
                                <p><span>Status:</span> Married</p>
                                <p><span>Diabetes type:</span> Type 2</p>
                                
                            </div>
                            <div className='item rounded-tr-3xl rounded-bl-3xl'>
                                <p className='text-[#036358] px-6 font-semibold text-base'>Goals</p>
                                <ul className='list-disc px-6 py-3'>
                                    <li>Ability to track his BGL without going to GP every time.</li>
                                    <li>Choose correct portion of Food, Fruits and Diary based on his health conditions and
                                        ensure getting the right amount of nutrients.
                                    </li>
                                </ul>
                            </div>
                            <div className='item rounded-tl-3xl rounded-br-3xl'>
                                <p className='text-[#036358] px-6 font-semibold text-base'>Pain Points</p>
                                <ul className='list-disc px-6 py-3'>
                                    <li>I tend to forget the right time to take my insulin and sometimes it causes a spike in my blood glucose level.</li>
                                    <li>He tends to eat traditional food which has lots of carbs, and struggles to find alternatives that are both satisfying and healthy.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id='scenarios'>
                <h1 className="work-subhead">Scenarios and Task analysis</h1>
                <p className='mb-10'>Collating and sorting the data we gathered from our interviews, we created personas and scenarios to best represent our target users;  Students, car owners, interns and engineers.</p>
                <div className='flex flex-col md:flex-row gap-10 text-sm'>
                    <p className='bg-[#01322D]/10 py-4 px-8 rounded-r-3xl rounded-bl-3xl'>
                    Mary Grace is a 27 years old banker who has been struggling with type two diabetes. Since she is busy, she doesn't have time to <span className='text-[#036358] font-bold'>keep track of her nutritional intake</span> and her BGL usually drops when she does not eat correctly. In addition to having diabetes, she also has diabetic retinopathy, which has affected her vision because the back of her eyes (retina) has been damaged by high blood sugar.
                    </p>
                    <p className='bg-[#01322D]/10 py-4 px-8 rounded-r-3xl rounded-bl-3xl'>
                    Her circumstances have motivated Mary to seek an application that offers readable fonts and helps her keep track of her blood glucose level and eat healthy every day. Knowing more about nutrition facts and their impact on the body as well as keeping a regular log of her blood glucose levels could help her control her eating habits.
                    </p>
                </div>
                <div className='flex flex-wrap items-center gap-2 my-10'>
                    <p className='scenario-flow'>User downloads App</p>
                    <Connector />
                    <p className='scenario-flow'>Log in meal by scanning barcode to view nutritional information</p>
                    <Connector />
                    <p className='scenario-flow'>View details ratings</p>
                    <Connector />
                    <p className='scenario-flow'>Track meal</p>
                </div>
            </div>
            <div id='info-architeture'>
                <h1 className="work-subhead">Information architecture</h1>
                <LazyLoadImage 
                    src='/assets/work/carbsbuddy/info-architecture.png'
                    className='w-full h-full'
                    effect='blur'
                    alt='alt'
                />
                <p className='text-sm md:text-base'>
                I was able to select major menus for the app. The idea was to give the best experience for the two different platforms I was designing for. Android users are said to still use the hamburger menu as a major navigation system and ios users are common with the bottom nav design style(this was concluded after looking at both platforms). The important menus were selected and arrange in the order of importance. The information architecture laid the foundation for the design process and eliminated uncertainty about where features will be located.
                </p>
            </div> 
            <div id='sketches'>
                <h1 className="work-subhead">Sketches - Wireframe - High fidelity</h1>
                <p className='mb-10 text-sm md:text-base'>Different aspects of the information architecture was sketched and after several iterations i moved on to the wireframe and the high fidelity design</p>
                <div className='flex flex-col md:flex-row gap-5'>
                    <LazyLoadImage 
                        src='/assets/work/carbsbuddy/sketches-1.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/carbsbuddy/sketches-2.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/carbsbuddy/sketches-3.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                </div>
                <p className='my-20 text-sm md:text-base text-[#333333]'>
                During our design process, we evaluated various approaches such as typography, button shapes and color choices. For color, we researched different options including blue, green, and purple. After careful consideration, we collectively decided on the color green as it is commonly associated with health and wellness.Similarly, we reviewed several typography options including sans-serif, Lexend, and Product Sans. We ultimately selected Nunito-sans after conducting accessibility research. After this we proceeded to do our design system and then the High-Fidelity design.
                </p>
                <LazyLoadImage 
                    src='/assets/work/carbsbuddy/wireframe.png'
                    className='w-full h-full'
                    effect='blur'
                    alt='alt'
                />
                <p className='my-10'>
                Different aspects of the information architecture was sketched and after several iterations i moved on to the wireframe and the high fidelity design
                </p>
                <LazyLoadImage 
                    src='/assets/work/carbsbuddy/high-fidelity-1.png'
                    className='w-full h-full'
                    effect='blur'
                    alt='alt'
                />
                <div>
                    <h2 className="work-subhead">High-fidelity</h2>
                    <p className='mb-10 text-sm md:text-base'>Different aspects of the information architecture was sketched and after several iterations i moved on to the wireframe and the high fidelity design</p>
                    <LazyLoadImage 
                        src='/assets/work/carbsbuddy/high-fidelity-2.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                </div>
                <div>
                    <h2 className="work-subhead">Prototype</h2>
                    <p className='mb-10 text-sm md:text-base'>Using Adobe XD, it was easy for me to prototype the design. I needed it to be like a real app since I will be testing using my mobile phone. So I prototyped all the major screens and the basic interactions and micro interactions to give users the feel of a live app.</p>
                    {/* <LazyLoadImage 
                        src='/assets/work/carbsbuddy/prototype.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    /> */}
                    <div>
                        <iframe loading='lazy' className='w-full h-[25vh] sm:h-[30vh] md:h-[40vh]' src="https://drive.google.com/file/d/1W9BsAa_7gsxZAnSwzfncGF1Z7TPRfrBR/preview" allow="autoplay"></iframe>
                    </div>
                </div>
            </div>
            <div id='usability-testing'>
                <h1 className="work-subhead">Usability testing</h1>
                <div className='flex flex-col gap-8 mb-10 text-sm md:text-base'>
                    <p>
                    The prototype was tested with 10 different users( 5 android, 5 IOS). The method of testing was to invite the users to use the app one by one and give them specific tasks and objectives.
                    </p>
                    <div>
                        <p>- Get started enter your academic details (Enter your details and get familiar with how 5dots can be of help to you)</p>
                        <p>- Set your academic goals (Use 5Dots to set your goals and see how to achieve your goals)</p>
                        <p>-Record an Audio (You are currently in a lecture hall and you are not understanding the lecture right now, record an audio of the lecture that you can listen to later)</p>
                    </div>
                    <p>
                    I took detailed notes on their interactions with the prototypes, noted all the feedback given. I also measured the time used to complete each task.All feedback was considered before moving to the next stage. I made revisions, referenced research and retested when needed.
                    </p>
                </div>
                
                <div className='text-sm md:text-base'>
                    <p>
                        <bold>Think Aloud Protocol:</bold> We encouraged them to talk through what they were doing verbalising that thoughts on actions. <bold>Observation:</bold> Observing the user behaviour and taking notes of them.Requested their thoughts & feedback on the application usage and what they found complex.
                    </p>
                    <div className='text-[#01322D] my-5'>
                        <h3 className='text-xl font-bold'>First SUS Testing</h3>
                        <p>Got a mixed review for the first round of test</p>
                        <p className='text-lg font-semibold'>SUS SCORE = 67.9%</p>
                    </div>
                    <div className='sm:grid grid-cols-2'>
                        <LazyLoadImage 
                            src='/assets/work/carbsbuddy/usability-testing-1.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                        <LazyLoadImage 
                            src='/assets/work/carbsbuddy/usability-testing-2.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                        <LazyLoadImage 
                            src='/assets/work/carbsbuddy/usability-testing-3.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                        <LazyLoadImage 
                            src='/assets/work/carbsbuddy/usability-testing-4.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                    </div>
                </div>
                <div className='text-sm md:text-base'>
                    <p>
                        <bold>Think Aloud Protocol:</bold> We encouraged them to talk through what they were doing verbalising that thoughts on actions. <bold>Observation:</bold> Observing the user behaviour and taking notes of them.Requested their thoughts & feedback on the application usage and what they found complex.
                    </p>
                    <div className='text-[#01322D] my-5'>
                        <h3 className='font-bold text-xl'>Second SUS Testing</h3>
                        <p>Got feedback on second round of test</p>
                        <p className='font-semibold text-lg'>SUS SCORE = 70%</p>
                    </div>
                    <LazyLoadImage 
                        src='/assets/work/carbsbuddy/usability-testing-5.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                </div>
            </div>
            <div id='future-enhancements' className='min-h-[30vh] flex flex-col justify-center'>
                <h1 className="work-subhead">Future Enhancements</h1>
                <p className='text-sm md:text-base'>
                Connecting healthcare and nutritional professionals.Option to connect with a wireless bluetooth glucose meter for automatic readings.Create hangout sessions from community engagementsInteraction with voice commands and receive hands-free assistance from Siri.
                </p>
            </div>    
        </div>
    </WorkLayout>
  )
}

export default CarbsBuddy