import React from 'react'
import Layout from '../components/Layout'

const About = () => {
  return (
    <Layout>
      <section className='bg-black'>
        <div className='flex items-center 2xl:container'>
          <div className='w-[50%] flex flex-col items-center gap-2 text-white'>
            <h2 className='text-4xl text-center font-medium'>Olajumoke Akerele</h2>
            <p className='text-[#F3F3F3]'>Product designer.</p>
          </div>
          <img className='w-[50%]' src="/assets/about/renee.png" alt="renee" />
        </div>
      </section>
      <section className='container text-black py-[5%] px-[10%]'>
        <div className='flex flex-col px-[10%] lg:text-lg gap-5 items-start'>
          <h1 className='text-2xl lg:text-3xl uppercase font-bold'>Hi, I'm Olajumoke</h1>
          <p className='text-left leading-relaxed'>Having recently completed my master's degree in User Experience Design, I've gained extensive knowledge in <span className='text-[#c30000]'>research, design principles, and accessibility standards.</span> I excel at simplifying complex problems into clear, user-friendly solutions, guided by data-driven insights. My work covers the full spectrum of product design, from ideating features to enhancing existing interfaces, always with a focus on user research, collaborative ideation, and creating accessible, developer-ready designs. My <span className='text-[#c30000]'>front-end development experience</span> ensures effective collaboration with developers, and my passion for continuous learning drives me to stay at the cutting edge of my field.</p>
          <p className='text-left leading-relaxed'>I’m based in United Kingdom but originally hail from a tiny town of Ayede in Ekiti, Nigeria. I have  a big love for travel and recently just started exploring. I love Jazz , Hip-hop/rap music and of course Afrobeat 😁</p>
          <p>This year i started reading books and i have a target of finishing a book each month.</p>
          <p>Thanks for stopping by. Hope you’ll reach out. 🙂</p>
        </div>
      </section>
      <section>
        <div className='container w-full grid grid-cols-2'>
          {[1,2,3,4].map((num) => (
            <img className='' key={num} src={`/assets/about/group${num}.png`} alt="" />
          ))}
        </div>
      </section>
      <section className='bg-[#1b1e23] text-white px-[10%] py-[10%]'>
        <div className='container px-10 border-l-2 border-[#db8d88]'>
          <div className='flex flex-col gap-4 md:pr-[40%] lg:text-lg leading-loose'>
            <p className='text-white'>“I've had the pleasure of working closely with Olajumoke, and I can confidently say that she is a hardworking and goal-oriented Product Designer. Her ability to craft intuitive and visually stunning user experiences is truly impressive. She brings passion, creativity and a touch of magic to every project, making her a valuable asset to any team. I highly recommend Olajumoke for her dedication and design prowess.”</p>
            <p className='text-white'>- Charles Jatau. Colleague at Stutern.</p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default About