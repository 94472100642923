import React from 'react'
import Layout from '../components/Layout'

const Contact = () => {
  return (
    <Layout className='bg-[#fff]'>
      <section className='container px-[5%] xl:px-0 py-[10%]'>
        <div className='contact flex flex-col md:flex-row items-start justify-between '>
          <div className='md:w-[40%] text-[#1b1717]'>
            <h1 className='text-4xl font-semibold'>I am thrilled to answer to your next project</h1>
            <p className='font-medium mt-4 md:mt-8'>Do you have a question/interesting ideas or projects? Leave amessage and I'll get back to you.</p>
          </div>
          <div className='mt-10 w-full md:mt-0 md:w-[40%]'>
            <form className='flex flex-col gap-3'>
              <label>
                <div>Name <span>(required)</span></div>
                <input type='text' required />
              </label>
              <label>
                <div>Email <span>(required)</span></div>
                <input type='email' required />
              </label>
              <label>
                <div>Message <span>(required)</span></div>
                <textarea rows={4} required />
              </label>
              <input className='bg-[#7f56d9] p-3 rounded cursor-pointer text-white' type="button" value="Send" />
            </form>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Contact