import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Layout from '../components/Layout'
import { Tools, projectsData } from '../data/Projects'
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <Layout className='bg-[#fff]'>
        <section className='py-[5%]'>
            <div className='container px-[5%] xl:px-0'>
                <div className='flex flex-col font-dmsans items-center gap-7 justify-between'>
                    <h1 className='text-3xl sm:text-4xl md:text-5xl lg:text-6xl leading-tight text-center font-medium'>Hello, I'm <br/> Olajumoke Akerele.</h1>
                    <h3 className='text-xl sm:text-2xl lg:text-3xl text-[#80868A] md:text-3xl font-medium text-center px-[10%]'>
                    A product designer with a knack for turning problems and opportunities into user-driven strategic solutions with delightful and intuitive interface design.
                    </h3>
                </div>
                <div className='hidden md:block my-20 animate-bounce'>
                    <img src="/assets/arrow-down.svg" alt="" />
                </div>
            </div>
            <div className='container px-[5%] xl:px-0 my-28'>
                <ul className='flex flex-col gap-20'>
                    {projectsData.map((project) => (
                        <div key={project.id} className='flex flex-col-reverse md:flex-row gap-8 pb-16 border-b-2 border-[#777]/50 justify-between'>
                            <div className='md:w-[30%] flex flex-col gap-10'>
                                <h2 className='font-bold text-2xl'>0{project.id}/ {project.name}</h2>
                                <p className='text-[#80868A] tracking-tight'>{project.info}</p>
                                <Link to={`/work/${project.name.split(" ").join("").toLowerCase()}`} className='flex w-fit text-[#181717] border-b border-[#181717] cursor-pointer hover:opacity-70 mt-auto'>
                                    <p>More details from this project</p>
                                    <img className='w-5' src="/assets/diagonal-arrow.svg" alt="arrow-diagonal" />
                                </Link>
                            </div>
                            <div className='md:w-[60%]'>
                                <LazyLoadImage
                                    src={project.image}
                                    width={600}
                                    height={400}
                                    // effect='blur'
                                    alt='alt'
                                />
                                {/* <img src={project.image} alt="alt" /> */}
                            </div>
                        </div>
                    ))}
                </ul>
            </div>
            <div className='md:container md:w-[75%] px-1 bg-[#f0ebff] flex flex-col items-center gap-5 rounded-lg py-10 lg:py-20 my-[5%]'>
                <div className='container space-y-3'>
                    <h3 className='font-medium text-[#475467] text-center text-lg'>My Tool stack</h3>
                    <ul className='flex flex-wrap justify-center items-center gap-5'>
                        {Tools.map((tool, index) => (
                            <img className='w-10 sm:w-14 md:w-fit' key={index} src={`/assets/${tool}.png`} alt={tool} />
                        ))}
                    </ul>
                </div>
            </div>
            <div className='md:px-[15%] xl:px-[20%] px-[8%] bg-[#fff] flex flex-col gap-12 md:flex-row items-center justify-between py-[5%]'>
                <div className='md:w-[60%] flex flex-col gap-5 font-dmsans'>
                    <h1 className='text-[32px] md:text-5xl text-center md:text-start font-bold'>Design-savvy lifestyle</h1>
                    <p className='text-lg text-center md:text-start text-[#80868A] font-normal leading-tight'>
                    I love creating and sharing inspiring design contents on my behance to connect with fellow designers and future clients. Check out some of my designs
                    </p>
                    <div className='flex gap-4 cursor-pointer items-center mt-5'>
                        <a href='https://www.behance.net/akerelejumoke_' target="_blank" rel="noopener noreferrer" className='bg-[#7F56D9] flex border border-black p-7 rounded-full'><img src="/assets/arrow-left-white.svg" alt="" /></a>
                        <p className='font-semibold'>See more of my Behance</p>
                    </div>
                </div>
                <div className='h-full w-[60%] sm:w-[50%] md:w-[30%]'>
                    <LazyLoadImage
                        className='w-full h-full'
                        src='/assets/oa-mockup.png'
                        width={300}
                        height={600}
                    />
                    {/* <img  src="/assets/oa-mockup.png" alt="design" /> */}
                </div>
            </div>
        </section>
    </Layout>
  )
}

export default Home