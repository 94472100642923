import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-scroll'
import WorkNavbar from './WorkNavbar'
import { projectsData } from '../../data/Projects'
import Spinner from '../Icons/Spinner'


const WorkLayout = ({PageNav, coverImg, description, children}) => {
    const workPaths = projectsData.map((project) => project.name.split(" ").join("").toLowerCase())
    const location = useLocation()
    const navigate = useNavigate()

    const nextPage = () => {
        let currentPage = location.pathname.replace('/work/','')

        for (let i = 0; i < workPaths.length; i++) {
            if (currentPage === workPaths[workPaths.length - 1]){
                navigate(`/work/${workPaths[0]}`)
            }  else if (currentPage === workPaths[i]) {
                //console.log(workPaths[i + 1])
                navigate(`/work/${workPaths[i + 1]}`)
            }
        }
    }

    const prevPage = () => {
        let currentPage = location.pathname.replace('/work/','')

        for (let i = 0; i < workPaths.length; i++) {
            if (currentPage === workPaths[0]){
                navigate(`/work/${workPaths[workPaths.length - 1]}`)
            }  else if (currentPage === workPaths[i]) {
                //console.log(workPaths[i + 1])
                navigate(`/work/${workPaths[i - 1]}`)
            }
        }
    }

  return (
    <section className='relative'>
        <div className="container py-5 px-2 sm:px-[5%] xl:px-0">
            <h2 className='text-[#1b1717] font-bold text-3xl md:py-5'>Work done</h2>
            <WorkNavbar nextPage={nextPage} prevPage={prevPage} />
        </div>
        <div className="md:container md:py-10 md:px-[5%] xl:px-0">
            <img className='mx-auto w-full h-full' src={coverImg} alt="work" />
        </div>
        {description && (
            <div className="container py-5 px-[5%] xl:px-0">
                <h1 className='text-3xl md:text-5xl text-start pr-[10%] lg:pr-[20%]'>{description}</h1>
            </div>
        )}
        {PageNav ? (<div className="container flex items-start justify-between py-10 px-[5%] xl:px-0">
            <nav className='hidden md:max-w-[25%] font-dmsans py-10 sticky top-[6em] md:flex flex-col gap-3'>
                {PageNav?.map((nav) => (
                    <Link 
                        className='cursor-pointer text-[#606060] text-[16px] flex items-center gap-5 transition ease-in-out duration-300 before:content-[""] before:flex before:bg-[#d9d9d9] before:h-[0.25rem] before:w-[0.25rem] before:rounded-full before:ease-in before:transition-all' 
                        activeClass='font-bold text-black before:rounded-none before:w-[2rem] before:h-[0px] before:bg-[transparent] before:border-b before:border-black/70 before:transition-all before:duration-300 before:ease-in'
                        smooth
                        spy
                        offset={-100}
                        duration={500}
                        key={nav.id} 
                        to={nav.path}>
                            <p className=''>{nav.title}</p>
                    </Link>
                ))}
            </nav>
            {children}
        </div>) : (
                <div className='my-28 flex gap-5 items-center justify-center text-center '>
                    <p className='text-5xl text-[#7f56d9] capitalize font-semibold tracking-wider'>Work in progress</p>
                    <Spinner />
                </div>
            )}
        <div className="container">
          <div onClick={nextPage} className='cursor-pointer mx-auto flex gap-4 items-center justify-center border-y border-black/20 py-10 lg:py-16 hover:gap-14 transition-all duration-300 ease-in'>
            <h2 className='sm:text-lg md:text-xl lg:text-2xl text-[#5913F1] font-medium'>That's the end of this project. <br/> Wanna hop to the next?</h2>
            <img className='w-6 h-6 md:w-10 md:h-10' src="/assets/arrow-right.svg" alt="" />
          </div>
      </div>
    </section>
  )
}

export default WorkLayout