import React from 'react'
import WorkLayout from '../../components/Work/Layout'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const PageNav = [
    {
        id: 1,
        title: 'Introduction',
        path: 'introduction'
    },
    {
        id: 2,
        title: 'Aims and RQ',
        path: 'aims-and-rq'
    },
    {
        id: 3,
        title: 'Initial Research',
        path: 'initial-research'
    },
    {
        id: 4,
        title: 'Methodology',
        path: 'methodology'
    },
    {
        id: 5,
        title: 'Sketches and Iterations',
        path: 'sketches'
    }, 
    {
        id: 6, 
        title: 'High fidelity designs and prototype',
        path: 'high-fidelity'
    },
    {
        id: 7,
        title: 'User testing',
        path: 'user-testing'
    },
    {
        id: 8,
        title: 'Results and findings',
        path: 'results-and-findings'
    }, 
    {
        id: 9,
        title: 'Discussions',
        path: 'discussions'
    }, 
    {
        id: 10,
        title: 'Limitations',
        path: 'limitations'
    }, 
    {
        id: 11,
        title: 'Recommenadations and conclusion', 
        path: 'recommendations'
    }
]

const GamificationInHealth = () => {
  return (
    <WorkLayout
        PageNav={PageNav}
        id="#gamification" 
        coverImg='/assets/work/Gamification.png'
    >
         <div 
            className='md:max-w-[70%] py-10 flex flex-col justify-between gap-5 min-h-screen'
        >
            <div id='introduction' className=''>
                <h1 className="work-subhead">Introduction</h1>
                <div className='space-y-5 text-sm lg:text-base'>
                    <p>The UK healthcare system, particularly the NHS, faces challenges such as rising chronic diseases and resource constraints.In response, mobile health (mHealth) applications have emerged as a transformative tool in healthcare, offering innovative solutions to these issues, they have ushered in a new era of advancements in healthcare system and they have the potential to empower indviduals to take control of their health and proactively detect and/or prevent chronic diseases.</p>
                    <p>This study explores the role of gamification in these apps, a strategy that leverages game design elements to enhance user engagement and interaction, potentially leading to improved health outcomes and adherence to health objectives .</p>
                </div>
            </div>
            <div id='aims-and-rq' className='py-10'>
                <h1 className="work-subhead">Aims and Research Questions</h1>
                <div className=''>
                    <LazyLoadImage 
                        src='/assets/work/gamification/aims-and-research.png'
                        className='w-full h-full mt-6'
                        effect='blur'
                        alt='alt'
                    />
                </div>
                <div className='space-y-6 my-6'>
                    <p>RQ1: Can the user experience of preventive health applications be optimised to encourage engagement from users in order to enhance their health</p>
                    <p>RQ2: Will gamification and reward systems have any effect on user engagement with preventive health?</p>
                    <p>This project aims to investigate various gamification levels in preventative health apps in order to determine how they affect user engagement.</p>
                </div>
            </div>
            <div id='initial-research' className=''>
               <h1 className="work-subhead">Initial Research</h1>
               <div>
                    <LazyLoadImage 
                        src='/assets/work/gamification/initial-research.png'
                        className='w-full h-full my-10'
                        effect='blur'
                        alt='alt'
                    />
               </div>
               <div className='space-y-7 text-[#3E3E3E]'>
                <p>Intial research was done where I undertook a comprehensive exploration into user engagement with mobile applications, with a particular focus on the role of gamification and other design elements like notifications, reminders, and widgets in fostering prolonged user interaction. This exploration was guided by a meticulously crafted questionnaire aimed at gauging users' familiarity with and reaction to gamification features, their patterns of application usage beyond conventional categories like banking and social media, and the factors that motivate their loyalty and consistent use of these apps. From ensuring participant consent and confidentiality to segmenting demographic insights and examining the duration and reasons behind app loyalty, the questionnaire covered a wide spectrum of inquiry. </p>
                <p>The findings from this research are visually represented through a series of infographics, charts, and graphs that illuminate key statistics, such as the percentage of users engaged with gamification, their average app usage duration, and the primary reasons for their prolonged engagement. These visual elements are complemented by quotes from respondents, offering personal insights into the motivations behind app usage and engagement, thereby adding a narrative depth to the data. The main findings are succinctly summarized, highlighting the pivotal role of gamification, effective reminders, and user-friendly interfaces in enhancing user engagement. </p>
               </div>
            </div>
             <div id='methodology' className=''>
                <h1 className="work-subhead">Methodology</h1>
                <div className='my-6 space-y-8'>
                    <p>Recent studies have highlighted gamification's potential in health applications to boost user engagement and promote proactive health management. Despite recognizing the need for personalized gamification strategies, there's a gap in empirical evidence regarding the impact of different gamification levels on user experience and long-term engagement. This study aims to fill this gap by exploring how various degrees of gamification affect user engagement, offering a deeper insight into effective gamification practices in health apps.</p>
                    <p>Adopting a mixed-method approach, this research combines qualitative and quantitative methodologies to assess gamification's influence on engagement. Participants interacted with a health app at low, medium, and high gamification levels. The System Usability Scale (SUS) provided quantitative usability data, while structured interviews yielded qualitative feedback on participants' experiences. This strategy allowed for a detailed evaluation of gamification's role in improving user engagement and application usability, contributing to the broader understanding of gamification's effectiveness in health-focused applications.</p>
                </div>
            </div>
            <div id='sketches' className=''>
                <h1 className="work-subhead">Sketches and Iterations</h1>
                <p>Sketches served as a foundational step in visualizing and refining how game-like elements could be seamlessly integrated into the app's interface. The goal was not only to elevate user enjoyment and satisfaction but also to encourage habitual and sustained interaction with the app. Through iterative design and feedback loops, these sketches evolved, each iteration inching closer to the optimal balance between fun, challenge, and user motivation.</p>
                <div>
                    <LazyLoadImage 
                        src='/assets/work/gamification/sketches.png'
                        className='w-full h-full my-4'
                        effect='blur'
                        alt='alt'
                    />
                </div>
            </div>
            <div id='high-fidelity' className=''>
                <h1 className="mini-head text-[28px]">High Fidelity Designs and Prototypes</h1>
                <div className='my-6 space-y-6'>
                    <p>Transitioning from sketches to high-fidelity designs and interactive prototypes marked a pivotal phase in enhancing the app’s gamification elements. This process involved refining visual and interactive details to evoke positive emotional responses and encourage user engagement. Through interactive prototypes,  user interactions were simulated, allowing for real-world feedback and iterative adjustments. This ensured the gamification features were visually appealing and seamlessly integrated, balancing engagement with the app's core functionalities.</p>
                    <p>It was a journey that tested various levels of gamification within the care plan context, observing user responses and tailoring the experience to captivate and fulfill users deeply. Through this process, we were committed to creating an app that not only engages users but also significantly contributes to their preventive care journey.</p>
                </div>
                <div className='my-16'>
                    <h2 className='mini-head text-[28px]'>Low gamification</h2>
                    <p>At this level, fundamental gamification features such as user friendly interface , acknowledgement for completing tasks and basic visual feedback were used.The goal was to see how little gamification affects user motivation and app usage.</p>
                    <div className='gamification-card'>
                        <LazyLoadImage 
                            src='/assets/work/gamification/low-gamification.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                        <p className='view-proto-btn'>View Prototype</p>
                    </div>
                </div>

                <div>
                    <h2 className='mini-head text-[28px]'>Mid gamification</h2>
                    <p>More complicated components such as earning health points after each task to enter into challenges, the use of leaderboards, badges, and intermediate awards were implemented. </p>
                    <div className='gamification-card'>
                        <LazyLoadImage 
                            src='/assets/work/gamification/mid-gamification.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                        <p className='view-proto-btn'>View Prototype</p>
                    </div>
                </div>

                <div className='my-16'>
                    <h2 className='mini-head text-[28px]'>High gamification</h2>
                    <p>A full gamification strategy was used at this level, which included a leaderboard, rewards, gamified interface, streaks, leveling system, badges complex point system, and numerous levels of challenges.</p>
                    <div className='gamification-card'>
                        <LazyLoadImage 
                            src='/assets/work/gamification/high-gamification.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                        <p className='view-proto-btn'>View Prototype</p>
                    </div>
                </div>
            </div>

            <div id='user-testing'>
                <h1 className="work-subhead">User Testing</h1>
                <div>
                    <p className='my-5'>The aim was to meticulously refine the user experience of our health application, ensuring it not only meets but surpasses the expectations and needs of our target demographic.</p>
                    <div className='my-5 space-y-6'>
                        <div className='flex flex-col'>
                            <p>Participant Demographics Overview</p>
                            <p>Through an engaging infographic, we present a concise overview of our user testing phase:</p>
                            <span>- 18 Participants aged between 20-45 years were meticulously selected.</span>
                            <span>- Recruitment was conducted online, with tests administered in-person to capture genuine feedback.</span>
                            <span>- A counterbalance strategy was employed to negate order effects, underpinned by informed consent from all participants.</span>
                            <span>- Testing involved using an iPhone for the app and an iPad for feedback via the System Usability Scale (SUS) and interviews.</span>
                        </div>
                        <div className='space-y-5'>
                            <p>Engagement Tasks & Methodology</p>
                            <p>A clear flow diagram illustrates the core tasks participants undertook, offering a visual narrative of their journey through daily activity continuation and meal plan initiation to completion. This is followed by a detailed process flowchart explaining our methodology from online recruitment to the collection of insightful feedback, highlighting the seamless integration of technology in our approach.</p>
                        </div>
                        <div className='flex flex-col'>
                            <p>Feedback & Insights</p>
                            <p>Post interview questions was asked after the testing to gain more insights and it was based on;</p>
                            <span>- Ease of Use and Navigation:Key observations on the app’s intuitive design.</span>
                            <span>- Engagement and Interest: Insights into elements that captivated users or needed improvement.</span>
                            <span>- Visual Design and Layout: Feedback on the app’s aesthetics and functionality.</span>
                            <span>- Overall Satisfaction & Preference: General user sentiment and preferred features.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div id='results-and-findings'>
                <h1 className="work-subhead">Results and Findings</h1>
                <div>
                    <LazyLoadImage 
                        src='/assets/work/gamification/results-cover.png'
                        className='w-full h-full my-6'
                        effect='blur'
                        alt='alt'
                    />
                </div>
                <div>
                    <h2 className='mini-head text-[28px]'>Quantitative</h2>
                    <p>The quantitative findings of the study, centered around the System Usability Scale (SUS) scores, revealed significant insights into the impact of different gamification levels on user engagement in a health application. The SUS scores varied notably across the three levels of gamification - Low, Mid, and High.The Low level demonstrated moderate usability with scores ranging between 65 and 97.5, indicating varied user perceptions regarding its simplicity.The Mid level exhibited a broader range of SUS scores, from 35 to 100, suggesting a wide variance in how users perceieves the usability of gamified elements .Interestingly, the High gamification level received the highest SUS values, signaling a preference among users for more intricate and interactive features. These scores ranged from 72.5 to 100, with several participants assigning higher ratings.</p>
                </div>
                <div className='py-6'>
                    <h2 className='mini-head text-[28px]'>Qualitative (Thematic analysis)</h2>
                    <div className='my-5 space-y-6'>
                        <p>Using Thematic analysis, several themes emerged from the qualitative data, reflecting participants' experiences and perceptions.</p>
                        <p>Theme 1: Usability and Navigation ; Users noted the simplicity of the Low level and the intuitive design of the High level, enhancing usability. For instance, a participant remarked, "This is simple and straight to the point."</p>
                        <p>Theme 2: Engagement and Motivation ; The Mid and High levels, with features like leaderboards and progress tracking, were found to be motivational. "There was a leader board, and I was competing, which was motivating me," shared one participant.</p>
                        <p>Theme 3: Complexity and Cognitive Load ; Some participants felt the Mid level was complex and potentially overwhelming. "Feels a bit complex. Not straight to the point," a user commented, indicating a higher cognitive load.</p>
                        <p>Theme 4: Reward and incentivization ; The High level’s rewards system was appreciated. "I like that I could purchase something with the tokens," a participant expressed, highlighting the sense of achievement.</p>
                        <p>Theme 5 : Visual Design and Aesthetics: Participants appreciated the visual appeal, especially in the High level. "The images will keep me engaged," noted one user.</p>
                        <p>Theme 6 : Directness and Purpose ; Users favoured the Low level’s straightforwardness. "It's my favourite; it is so direct and simple," said a participant.</p>
                        <p>Theme 7: Personalization and Relevance; Personalization was crucial for user satisfaction, especially in the High level. A participant noted, "It appears appealing that I can spend the points.</p>
                        <p>These themes provide a more in-depth understanding of how gamification features influence user engagement and satisfaction in digital health systems.</p>
                    </div>
                </div>
                
            </div>
            <div id='discussions'>
                <h1 className="work-subhead">Discussions</h1>
                <div className='my-7 space-y-6'>
                    <p>The study's findings offer a nuanced understanding of how gamification influences user engagement and health outcomes in preventive health apps.The study reveals a crucial balance in app design between usability, engagement, and complexity. Mid gamification levels increase user engagement due to their interactive nature but also raise complexity, potentially causing cognitive overload and user disengagement.</p>
                    <p>Personalization emerged as a crucial factor in enhancing user experience. The study found that users prefer gamification elements that are tailored to their individual health journeys, suggesting that customization is essential for sustained engagement and effective health improvement. This aligns with the principles of user-centric design, emphasizing the importance of aligning app features with specific user needs and preferences.</p>
                    <p> The findings underscore the importance of designing health apps that strike a balance between engaging users through gamification and maintaining simplicity and directness to facilitate ease of use and adherence to health goals .</p>
                    <p>RQ: Will gamification and reward systems have any effect on user engagement with preventive health?The study found that for mid and high gamification incorporating elements like leaderboards and point systems, significantly increased engagement and motivation. The study emphasized the importance of balanced gamification and reward systems. </p>
                </div>
                
            </div>
            <div id='limitations' className='py-10'>
                <h1 className="work-subhead">Limitations</h1>
                <p>
                Although this study provides valuable insights, it is not exempt from its limitations. The study size of 18 participants may not sufficiently capture the characteristics of the wider population that utilises preventive health apps, thereby potentially restricting the applicability of the findings. Furthermore, the sample's demographic variety was inadequately described, which prompts concerns regarding the generalizability of the findings to various age groups, cultural backgrounds, and health conditions.
                </p>
            </div>   
            <div id='recommendations'>
                <h1 className="work-subhead">Recommendations and Conclusion</h1>
                <div className='my-4'>
                    <LazyLoadImage 
                        src='/assets/work/gamification/recommendations-cover.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                </div>
                <div className='space-y-8 my-10'>
                    <p>Although this study provides valuable insights, it is not exempt from its limitations. The study size of 18 participants may not sufficiently capture the characteristics of the wider population that utilises preventive health apps, thereby potentially restricting the applicability of the findings. Furthermore, the sample's demographic variety was inadequately described, which prompts concerns regarding the generalizability of the findings to various age groups, cultural backgrounds, and health conditions.</p>
                    <p>The study's findings offer a nuanced understanding of how gamification influences user engagement and health outcomes in preventive health apps.The study reveals a crucial balance in app design between usability, engagement, and complexity. Mid gamification levels increase user engagement due to their interactive nature but also raise complexity, potentially causing cognitive overload and user disengagement.</p>
                    <p>Personalization emerged as a crucial factor in enhancing user experience. The study found that users prefer gamification elements that are tailored to their individual health journeys, suggesting that customization is essential for sustained engagement and effective health improvement. This aligns with the principles of user-centric design, emphasizing the importance of aligning app features with specific user needs and preferences.</p>
                    <p>The findings underscore the importance of designing health apps that strike a balance between engaging users through gamification and maintaining simplicity and directness to facilitate ease of use and adherence to health goals. <br/> RQ: Will gamification and reward systems have any effect on user engagement with preventive health?The study found that for mid and high gamification incorporating elements like leaderboards and point systems, significantly increased engagement and motivation. The study emphasized the importance of balanced gamification and reward systems. </p>
                </div>
            </div> 

        </div>
    </WorkLayout>
  )
}

export default GamificationInHealth