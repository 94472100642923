import React from 'react'
import { NavLink } from 'react-router-dom'

const MobileNav = ({ nav, navitems, toggleNav }) => {
  return (
    <div className={nav ? 'mobilenav translate-x-[0rem] ease-out transition opacity-100 duration-[600ms]' : 'mobilenav translate-x-[-120rem] transition ease-in duration-500'}>
        <div className='py-8 mx-6 backdrop-blur-2xl bg-white shadow-xl rounded-xl border-[1px] border-[#777]'>
            <div className='flex flex-col gap-4 text-sm items-center'>
                {navitems.map((nav, index) => (
                    <NavLink onClick={toggleNav} key={index} className='font-body text-[black] text-base cursor-pointer hover:opacity-50' to={nav.href}>
                        {nav.title}
                    </NavLink>
                ))}
            </div>
        </div>
    </div>
  )
}

export default MobileNav