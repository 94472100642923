export const projectsData = [
    {
        id: 1,
        name: "Carbsbuddy",
        info: "Carbsbuddy focuses on promoting healthier eating among individuals with diabetes to improve their quality of life and reduce complications by enhancing awareness, motivation, and support.",
        image: "/assets/Carbsbuddy.png",
        color: "#046459"
    },
    {
        id: 2,
        name: "Versal Finance",
        info: "Versal Finance is a budgeting app designed for everyone, prioritizing accessibility to ensure users with diverse abilities can manage their finances easily and effectively.",
        image: "/assets/Versal-finance.png",
        color: "#2E5BBE"
    },
    {
        id: 3,
        name: "Gamification in Health",
        info: "Versal Finance is a budgeting app designed for everyone, prioritizing accessibility to ensure users with diverse abilities can manage their finances easily and effectively.",
        image: "/assets/Gamification.png",
        color: "#6A44C7"
    },
    {
        id: 4,
        name: "For Wheels",
        info: "Forwheels is an innovative automobile learning application that elevates user education by introducing new concepts through visually captivating and interactive methods.",
        image: "/assets/For-wheels.png",
        color: "#CD471E"
    },
    {
        id: 5,
        name: "Buddie",
        info: "Buddie revolutionizes finding and enjoying local events by matching users with companions who share their interests, all within a secure app environment, ensuring a communal and safe experience for every outing.",
        image: "/assets/Buddie.png",
        color: "#389394"
    }
];

export const Tools = [
    'Figma',
    'Adobe',
    'Slack',
    'Notion',
    'Drive',
    'Calendar'
]