import React, {useState} from 'react';
import { Link, NavLink } from 'react-router-dom';
import MobileNav from './MobileNav';

const navItems = [
    {
        "title": "Home",
        "href": "/"
    },
    {
       "title": "Work",
       "href": "/work" 
    },
    {
        "title": "About",
        "href": "/about"
    },
    {
        "title": "Resume",
        "href": "/resume"
    },
    {
        "title": "Contact",
        "href": "/contact"
    }
]

const Navbar = () => {
    const [nav, setNav] = useState(false)

    const toggleNav = () => {
        setNav(!nav)
    }

  return (
        <nav className='bg-[#fff] border-b border-black/5 z-10 mx-auto sticky top-0 w-full mb-[32px]'>
            <div className='container px-[5%] xl:px-0 flex justify-between items-center'>
                <Link to='/'>
                    <img src='/assets/oa-logo.png' alt='logo' /> 
                </Link>
                <ul className='hidden md:flex text-sm gap-8'>
                    {navItems.map((nav, index) => (
                        <NavLink
                            key={index} 
                            to={nav.href}
                            className={({ isActive }) => isActive ? "navitem active font-bold" : "navitem"}
                        >
                            {nav.title}
                            <span className='hidden bg-[#7f56d9] rounded-full w-2 h-2'></span>
                        </NavLink>
                    ))}
                </ul>
                <div className='md:hidden'>
                    <button onClick={toggleNav} data-toggle-nav data-open-nav={nav} className="lg:hidden lg:invisible outline-none w-7 h-auto flex flex-col relative z-[105]">
                        <span id="line1" className="bg-black w-6 h-[2px] rounded-full bg-heading-2 transition-all duration-300 ease-linear"></span> 
                        <span id="line2" className="bg-black w-6 origin-center mt-1 h-[2px] rounded-full bg-heading-2 transition-all duration-300 ease-linear"></span> 
                        <span id="line3" className="bg-black w-6 mt-1 h-[2px] rounded-full bg-heading-2 transition-all duration-300 ease-linear"></span>                         
                        <span className="sr-only">togglenav</span>
                    </button>
                </div>
            </div>
            <MobileNav nav={nav} toggleNav={toggleNav} navitems={navItems} />               
        </nav>
  )
}

export default Navbar