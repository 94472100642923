import { Link, useRouteError } from "react-router-dom";
import Layout from '../components/Layout'

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Layout>
        <div className="flex flex-col justify-center w-full items-center min-h-[50vh]" id="error-page">
            <img src='/assets/404.png' alt='' />
        {/* <h1>Oops!</h1> */}
        <p className="text-2xl font-medium">Sorry, an unexpected error has occurred.</p>
        <p>
            <i>- {error.statusText || error.message}</i>
        </p>
        <Link className="mt-10 flex items-center text-[#7f56d9]" to='/'>Go home <img className="w-5" src="assets/diagonal-arrow.svg" alt="" /></Link>
        </div>
    </Layout>
  );
}