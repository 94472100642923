import React from 'react'
import WorkLayout from '../../components/Work/Layout'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const PageNav = [
    {
        id: 1,
        title: 'Summary',
        path: 'summary'
    },
    {
        id: 2,
        title: 'Project Overview',
        path: 'overview'
    },
    {
        id: 3,
        title: 'Project Scope',
        path: 'scope'
    },
    {
        id: 4,
        title: 'User Research',
        path: 'user-research'
    },
    {
        id: 5,
        title: 'User Persona',
        path: 'user-persona'
    }, 
    {
        id: 6, 
        title: 'Competitors Research',
        path: 'competitors-research'
    },
    {
        id: 7,
        title: 'Accessibility',
        path: 'accessibility'
    },
    {
        id: 8,
        title: 'Ideation and Prototyping',
        path: 'ideation'
    }, 
    {
        id: 9,
        title: 'Sketches',
        path: 'sketches'
    }, 
    {
        id: 10,
        title: 'High Fidelity Screens',
        path: 'high-fidelity'
    }, 
    {
        id: 11,
        title: 'Usability testing', 
        path: 'usability-testing'
    }, 
    {
        id: 12,
        title: 'Dark Mode',
        path: 'dark-mode'
    },
    {
        id: 13,
        title: 'Multi-platform Accessibility',
        path: 'multi-platform-accessibility'
    }
]

const VersalFinance = () => {
  return (
     <WorkLayout 
        PageNav={PageNav} 
        id="#versal-finance" 
        //description={'Budgeting with inclusive design as the focus for a better budget work'}
        coverImg='/assets/work/versalfinance.png'
    >
        <div 
            className='md:max-w-[70%] py-5 md:py-10 flex flex-col justify-between gap-5 min-h-screen'
        >
            <div id='summary' className=''>
                <h1 className='text-3xl md:text-5xl'>Budgeting with inclusive design as the focus for a better budget work</h1>
                <h1 className="work-subhead my-1 mt-10 text-lg font-semibold">Summary</h1>
                <p className='text-faded text-sm md:text-base text-start md:pr-[15%] xl:pr-[5%]'>
                The Versal Financial project showcases the development of an accessible financial management app designed to cater to a broad user base, including individuals with disabilities. Key features include expense tracking, budget monitoring, and analytics, all built with a focus on inclusivity and accessibility. Through rigorous disability analysis and adherence to the Web Content Accessibility Guidelines (WCAG), the app ensures a seamless user experience for everyone.
                </p>
                <p className='mt-4 text-faded text-sm md:text-base text-start md:pr-[15%] xl:pr-[5%]'>Personas representing diverse needs guided the design process, from paper prototyping to high-fidelity development, emphasizing user-centered design and iterative feedback to refine and enhance the app's usability and accessibility.</p>
            </div>
            <div id='overview'  className=''>
                <h1 className="work-subhead">Project Overview</h1>
                <div className="space-y-10">
                    <div>
                        <h2 className='font-semibold text-base my-2'>The Product</h2>
                        <p className='text-faded text-sm md:text-base text-start md:pr-[15%] xl:pr-[5%]'>
                        Versal Financial is designed to help a wide range of people handle their financial affairs. It delivers a seamless experience for users of diverse backgrounds and disabilities to track their expenses, build budgets, and get detailed analysis of their spending in real time, with a focus on accessibility and diversity. 
                        </p>
                    </div>
                    <div className='my-4'>
                        <h2 className='font-semibold text-base md:text-lg my-2'>My Role</h2>
                        <p className='text-faded text-sm md:text-base text-start'>UI/UX Designer</p>
                    </div>
                    <div className='my-4'>
                        <h2 className='font-semibold text-base md:text-lg my-2'>My Role</h2>
                        <p className='text-faded text-sm md:text-base text-star md:pr-[15%] xl:pr-[5%]'>User Researcher , UI/UX Design , Designed  the design system, user flow, wireframes, interactive prototypes </p>
                    </div>
                </div>
                <div className=''>
                    <LazyLoadImage
                        className='w-full h-full my-16' 
                        src='/assets/work/versalfinance/design-process.png'
                        effect='blur'
                        alt='alt'
                    />
                </div>
                <div className='text-faded text-sm md:text-base py-8'>
                    <h2 className='font-semibold text-base text-black my-2'>Project Goal Outline</h2>
                    <p>Enhance User Experience:</p>
                    <div className='space-y-9 my-5'>
                        <div className=''>
                            <p>A. Improve Interface: </p>
                            <ul>
                                <li>Conduct user surveys and feedback analysis.</li>
                                <li>Implement a more intuitive and user-friendly design.</li>
                                <li>Optimise navigation for a seamless user experience.</li>
                            </ul>
                        </div>
                        <div className=''>
                            <p>B. Accessibility:</p>
                            <ul>
                                <li>Ensure compatibility across various devices and platforms.</li>
                                <li>Implement features to enhance accessibility for users with disabilities.</li>
                                <li>Conduct usability testing to identify and address any user experience pain points.</li>
                            </ul>
                        </div>
                        <div className=''>
                            <p>C. Account Integration:</p>
                            <ul>
                                <li>Increase the number of supported banks and financial institutions.</li>
                                <li>Enhance the synchronisation of account data for accuracy.</li>
                                <li>Explore partnerships for seamless integration with external financial tools.</li>
                            </ul>
                        </div>
                        <div className=''>
                            <p>D. Advanced Budgeting Tools:</p>
                            <ul>
                                <li>Develop and implement advanced budgeting features.</li>
                                <li>Provide personalized financial insights and recommendations.</li>
                                <li>Enable users to set and track specific financial goals within the app.</li>
                            </ul>
                        </div>
                        <div className=''>
                            <p>E. Payment Flexibility:</p>
                            <ul>
                                <li>Integrate additional payment methods e.g. digital wallets.</li>
                                <li>Streamline bill payment processes for increased efficiency.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <LazyLoadImage 
                    src='/assets/work/versalfinance/overview-2.png'
                    className='w-full h-full my-10'
                    effect='blur'
                    alt='alt'
                />
            </div>
            <div id='scope'  className='py-16'>
               <h1 className="work-subhead">Scope of Project</h1>
               <LazyLoadImage
                    className='w-full h-full my-10' 
                    src='/assets/work/versalfinance/scope.png'
                    effect='blur'
                    alt='alt'
                />
            </div>
             <div id='user-research'  className='my-10'>
                <h1 className="work-subhead">User Research</h1>
                <LazyLoadImage 
                    src='/assets/work/versalfinance/user-research.png'
                    className='w-full h-full'
                    effect='blur'
                    alt='alt'
                />
                <div className='w-full flex flex-wrap gap-3 font-bold text-[#315CBC] text-sm justify-around items-center py-10'>
                    <div className='ur-card'>
                        <img src="/assets/work/versalfinance/interview-svg.svg" alt="icon" />
                        <p>Interviews</p>
                    </div>
                    <div className='ur-card'>
                        <img src="/assets/work/versalfinance/analysis-svg.svg" alt="icon" />
                        <p>Competitive analysis</p>
                    </div>
                    <div className='ur-card'>
                        <img src="/assets/work/versalfinance/persona-svg.svg" alt="icon" />
                        <p>User personas</p>
                    </div>
                    <div className='ur-card'>
                        <img src="/assets/work/versalfinance/journey-svg.svg" alt="icon" />
                        <p>User journey map</p>
                    </div>
                </div>
                <div className='flex justify-between items-center'>
                    <div className='hidden md:block w-[40%]'>
                        <LazyLoadImage 
                            src='/assets/work/versalfinance/research-chart.png'
                            effect='blur'
                            alt='alt'
                        />
                    </div>
                    <div className='md:w-[55%] text-faded text-sm'>
                        <h2 className='mini-head-base'>Interview with end users</h2>
                        <p>
                        For the versal project, my goal in conducting user interview is to connect directly with our end users , understand their unique needs, and gain firsthand insights to inform the design of our online banking app . I want to create an interface that only meets but exceeds their expectations.
                        </p>
                        <div>
                            <h2 className='mini-head-base'>Process:</h2>
                            <div className='int-card'>
                                <h3 className='mini-head-base'>Participant selection</h3>
                                <ul>
                                    <li>Personally handpicked a diverse group of partcipamts, ensuring they represented actual users we aim to serve.</li>
                                    <li>Included individuals with varying levels of familiarity with online banking a range of financial needs.</li>
                                </ul>
                            </div>
                            <div className='int-card'>
                                <h3 className='mini-head-base'>Questionnaire development</h3>
                                <ul>
                                    <li>Crafted a tailored set of open-ended questions that reflect our project specific goals</li>
                                    <li>Designed questions that delve deep into participants experiences aiming to uncover their stories and preferences</li>
                                </ul>
                            </div>
                            <div className='int-card'>
                                <h3 className='mini-head-base'>Conducting interviews</h3>
                                <ul>
                                    <li>Scheduled and personally conducted one-on -one virtual interviews with my interpersonal skill to connect with users encouraging them to provide detailed and honest feedback and in return we offered $50 Amazon voucher to each person at the end of the interview.</li>
                                </ul>
                            </div>
                            <div className='int-card'>
                                <h3 className='mini-head-base'>Observations and Probing</h3>
                                <ul>
                                    <li>Watched and pay attention to the body languagr and facial expressions of participant during interviews, noting subtle reactions. </li>
                                    <li>Skilfully used probing techniques to dig deeper into specific topics and gained a richer understanding of participants’ perspectives.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='my-8'>
                    <LazyLoadImage 
                        src='/assets/work/versalfinance/interview-feedback.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                </div>
            </div>
            <div id='user-persona'  className=''>
                <h1 className="work-subhead">User Persona</h1>
                <p>The insights I got from the user interviews we conducted led to this user personas</p>
                <div className='persona-card block my-10'>
                    <p className='text-faded'>Persona 1:</p>
                    <div className='space-y-2 sm:grid grid-cols-2 gap-5 text-sm text-faded'>
                        <div className='up-card'>
                            <div className=''>
                                <img src="/assets/work/versalfinance/margaret-tamford.png" alt="" />
                                <h3>Margaret Tamford</h3>
                            </div>
                            <p>Gender: Female</p>
                            <p>Age: 64</p>
                            <p>Disability: Dyscalculia</p>
                        </div>
                        <div className='up-card'>
                            <div>
                                <img className='' src="/assets/work/versalfinance/bg.png" alt="" />
                                <h3>Background</h3>
                            </div>
                            <ul>
                                <li>
                                Margaret prefers traditional banking methods and is resistant to integrating technology with her financial activities, often visiting the bank in person for account updates.
                                </li>
                                <li>As she ages, Margaret finds the physical demands of these bank visits increasingly challenging and stressful, impacting her ability to manage her finances comfortably.</li>
                            </ul>
                        </div>
                        <div className='up-card'>
                            <div>
                                <img src="/assets/work/versalfinance/needs.png" alt="" />
                                <h3>Needs</h3>
                            </div>
                            <ul>
                                <li>
                                    Margaret needs a quick way to check her bank account.
                                </li>
                                <li>She requires an app with easy navigation to understand her financial inflows.</li>
                                <li>She wants to manage her account remotely to avoid visiting the bank.</li>
                            </ul>
                        </div>
                        <div className='up-card'>
                            <div>
                                <img src="/assets/work/versalfinance/pin.png" alt="" />
                                <h3>Pain Point</h3>
                            </div>
                            <ul>
                                <li>Apps confuse Margaret with complex numbers due to her dyscalculia.</li>
                                <li>She struggles to track her balance and transactions.</li>
                                <li>Visiting the bank to understand her account tires her out.</li>
                            </ul>
                        </div>
                        <div className='up-card col-span-2'>
                            <div>
                                <img src="/assets/work/versalfinance/scenario.png" alt="" />
                                <h3>User Scenario</h3>
                            </div>
                            <p>
                            Maggie, 75, struggles with dyscalculia, which complicates understanding numbers. As she adapts to changes in banking, she seeks an app that simplifies her financial overview. She found an app that displays her account balance clearly, using charts to visualize inflows and outflows. This app automatically categorizes transactions, making it easier for her to manage her finances without the physical strain of visiting the bank.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='persona-card my-10 block'>
                    <p className='text-faded'>Persona 2:</p>
                    <div className='space-y-2 sm:grid grid-cols-2 gap-5 text-sm text-faded'>
                        <div className='up-card'>
                            <div className=''>
                                <img src="/assets/work/versalfinance/margaret-tamford.png" alt="" />
                                <h3>John Smith</h3>
                            </div>
                            <p>Gender: Male</p>
                            <p>Age: 45</p>
                            <p>Job: Civil Engineer</p>
                        </div>
                        <div className='up-card'>
                            <div>
                                <img className='' src="/assets/work/versalfinance/bg.png" alt="" />
                                <h3>Background</h3>
                            </div>
                            <ul>
                                <li>
                                Margaret prefers traditional banking methods and is resistant to integrating technology with her financial activities, often visiting the bank in person for account updates.
                                </li>
                                <li>As she ages, Margaret finds the physical demands of these bank visits increasingly challenging and stressful, impacting her ability to manage her finances comfortably.</li>
                            </ul>
                        </div>
                        <div className='up-card'>
                            <div>
                                <img src="/assets/work/versalfinance/needs.png" alt="" />
                                <h3>Needs</h3>
                            </div>
                            <ul>
                                <li>
                                    Margaret needs a quick way to check her bank account.
                                </li>
                                <li>She requires an app with easy navigation to understand her financial inflows.</li>
                                <li>She wants to manage her account remotely to avoid visiting the bank.</li>
                            </ul>
                        </div>
                        <div className='up-card'>
                            <div>
                                <img src="/assets/work/versalfinance/pin.png" alt="" />
                                <h3>Pain Point</h3>
                            </div>
                            <ul>
                                <li>Apps confuse Margaret with complex numbers due to her dyscalculia.</li>
                                <li>She struggles to track her balance and transactions.</li>
                                <li>Visiting the bank to understand her account tires her out.</li>
                            </ul>
                        </div>
                        <div className='up-card col-span-2'>
                            <div>
                                <img src="/assets/work/versalfinance/scenario.png" alt="" />
                                <h3>User Scenario</h3>
                            </div>
                            <p>
                            Maggie, 75, struggles with dyscalculia, which complicates understanding numbers. As she adapts to changes in banking, she seeks an app that simplifies her financial overview. She found an app that displays her account balance clearly, using charts to visualize inflows and outflows. This app automatically categorizes transactions, making it easier for her to manage her finances without the physical strain of visiting the bank.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div id='competitors-research'  className=''>
                <h1 className="work-subhead">Competitors Research</h1>
                <p>Based on the research, surveys and interviews conducted, i took time to analyse most popular online banking app in the market to better give a competitive edge and to find a unique selling feature for Versal finance.</p>
                <div className='my-5'>
                    <LazyLoadImage 
                        src='/assets/work/versalfinance/competitors.png'
                        className='h-full w-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/versalfinance/competitors-research.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                </div>
            </div>
            <div id='accessibility'>
                <h1 className="work-subhead">Accessibility considerations</h1>
                <p>Accessibility is a cornerstone of the design process for Versal Finance, with a strong focus on understanding user needs to enhance app usability. A critical aspect of this is adhering to WCAG guidelines, which are crucial for ensuring the app is inclusively designed for all users.</p>
                <div className='my-14 flex flex-col gap-7'>
                    <div className='card flex flex-col md:flex-row justify-between'>
                        <div className='mt-8 md:w-[20%]'>
                            <h2 className='text-xl font-bold text-[#090909]'>Inclusive design</h2>
                            <p className='text-[#6E6E6E] my-4 md:my-8 text-base'>Visual impairment consideration</p>
                        </div>
                        <div className='md:w-[80%] flex flex-col md:flex-row gap-5'>
                            <div className='md:w-[50%] rounded-2xl p-[1px] bg-gradient-to-r from-[#E4986A] to-[#E7C69B]'>
                                <ul className='list-disc h-full rounded-2xl bg-white flex flex-col gap-5 py-16 px-8 text-sm md:text-base'>
                                    <li>
                                    <bold>Technology Accessibility: </bold><span className='opacity-70'>In developed societies, technology such as screen readers (Voice Over for iOS, Talkback4 for Android) and larger fonts enhance independence for the visually impaired.</span>
                                    </li>
                                    <li>
                                    <bold>Adherence to WCAG Guidelines :</bold><span className='opacity-70'>Followed WCAG guideline 1.4.3 to select color combinations with high contrast, improving readability for Versal Finance users with visual impairments.</span> 
                                    </li>
                                </ul>
                            </div>
                            <div className='md:w-[50%] rounded-2xl p-[1px] bg-gradient-to-r from-[#717FF8] to-[#D6F2FD]'>
                                <ul className='list-disc h-full rounded-2xl bg-white flex flex-col gap-5 py-16 px-8 text-sm md:text-base'>
                                    <li>
                                    <bold>Optimized Text and Graphics :</bold><span className='opacity-70'> Conformed to WCAG guideline 1.4.4 by choosing readable font sizes and transforming complex graphs into tables for better clarity and ease of reading.</span>
                                    </li>
                                    <li>
                                    <bold>Enhanced Visual Design:</bold><span className='opacity-70'> Ensured accessibility by redesigning graphical information to be visually distinct not just by color but also texture, following WCAG guidelines 1.3.1 and 1.4.11.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='card flex flex-col md:flex-row justify-between'>
                        <div className='mt-8 md:w-[20%]'>
                            <h2 className='text-xl font-bold text-[#090909]'>Inclusive design</h2>
                            <p className='text-[#6E6E6E] my-4 md:my-8 pr-[10%]'>Auditory impairment</p>
                        </div>
                        <div className='md:w-[80%] flex flex-col md:flex-row gap-5 justify-between'>
                            <div className='md:w-[50%] rounded-2xl p-[1px] bg-gradient-to-r from-[#3356F5] to-[#F1B059]'>
                                <ul className='list-disc h-full rounded-2xl bg-white flex flex-col gap-5 py-16 px-8 text-sm md:text-base'>
                                    <li>
                                    <span className='opacity-70'>Integrated visual aids such as charts and tables, aligned with WCAG guideline 1.3.3. (Sensory Characteristics), offering multiple ways for Marcus to process financial information without relying on sound.</span>
                                    </li>
                                    <li>
                                    <span className='opacity-70'> Text throughout the app's interface is carefully arranged to ensure clarity and brevity, enhancing readability and comprehension.</span> 
                                    </li>
                                </ul>
                            </div>
                            <div className='md:w-[50%] rounded-2xl p-[1px] bg-gradient-to-r from-[#EF9C6C] to-[#9BD7FB]'>
                                <ul className='list-disc rounded-2xl bg-white flex flex-col gap-5 py-16 px-8 text-sm md:text-base'>
                                    <li>
                                    <span className='opacity-70'>Implemented financial chatbots and configurable features like larger fonts and dark mode, following WCAG guidelines 3.2.2 (On Input) and 3.3.2 (Labels or Instructions), to assist during complex budgeting tasks and improve overall accessibility.</span>
                                    </li>
                                    <li>
                                    <span className='opacity-70'> A holistic approach to design ensures that reliance on auditory cues is minimized, supporting full app accessibility and independence for users with hearing challenges, in line with WCAG 2.1.1 (Keyboard).</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='card flex flex-col md:flex-row justify-between'>
                        <div className='mt-8'>
                            <h2 className='text-xl font-bold text-[#090909]'>Inclusive design</h2>
                            <p className='text-[#6E6E6E] my-4 md:my-8'>Physical impairment</p>
                        </div>
                        <div className='md:w-[80%] flex flex-col md:flex-row gap-5 justify-between'>
                            <div className='md:w-[50%] rounded-2xl p-[1px] bg-gradient-to-r from-[#E4986A] to-[#E7C69B]'>
                                <ul className='list-disc h-full rounded-2xl bg-white flex flex-col gap-5 py-16 px-8 text-sm md:text-base'>
                                    <li>
                                    <span className='opacity-70'>Physical impairments like arthritis and Parkinsonism can hinder the ability to use computers and the internet, requiring advanced accessibility features to support basic online functions</span>
                                    </li>
                                    <li>
                                    <span className='opacity-70'>Versal incorporates voice input and a large touch target size (44px) to facilitate easier interaction with the app, making it accessible for users with limited mobility and reducing the risk of errors.</span> 
                                    </li>
                                </ul>
                            </div>
                            <div className='md:w-[50%] rounded-2xl p-[1px] bg-gradient-to-r from-[#717FF8] to-[#D6F2FD]'>
                                <ul className='list-disc rounded-2xl bg-white flex flex-col gap-5 py-16 px-8 text-sm md:text-base'>
                                    <li>
                                    <span className='opacity-70'>The application supports keyboard navigation and has simplified navigation options, complying with multiple WCAG guidelines such as 2.5.6 for concurrent input mechanisms and 3.2.3 for consistent navigation, ensuring a user-friendly experience for all users, including those with physical disabilities.</span>
                                    </li>
                                    <li>
                                    <span className='opacity-70'> Versal allows users to adjust font sizes according to their preferences, enhancing readability and usability under WCAG guideline 1.4.4 (Resize Text), promoting a better interaction environment.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='card flex flex-col md:flex-row justify-between'>
                        <div className='mt-8'>
                            <h2 className='text-xl font-bold text-[#090909]'>Inclusive design</h2>
                            <p className='text-[#6E6E6E] my-4 md:my-8 pr-[10%]'>Cognitive impairment</p>
                        </div>
                        <div className='md:w-[80%] flex flex-col md:flex-row gap-5 justify-between'>
                            <div className='md:w-[50%] rounded-2xl p-[1px] bg-gradient-to-r from-[#9AACFA] to-[#B3E5FC]'>
                                <ul className='list-disc h-full rounded-2xl bg-white flex flex-col gap-5 py-16 px-8 text-sm md:text-base'>
                                    <li>
                                    <span className='opacity-70'>The app is tailored for users like Margaret, an older woman with dyscalculia, focusing on simple and intuitive interfaces that enhance her ability to manage financial activities with visual aids like well-defined images, icons, and color-coded graphs.</span>
                                    </li>
                                    <li>
                                    <span className='opacity-70'>Features such as clear labeling, visual consistency, fast feedback, and responsive interactions address common design challenges faced by older adults, improving usability (Jin and Fan, 2022).</span> 
                                    </li>
                                </ul>
                            </div>
                            <div className='md:w-[50%] rounded-2xl p-[1px] bg-gradient-to-r from-[#FBE5D9] to-[#F6DBA5]'>
                                <ul className='list-disc h-full rounded-2xl bg-white flex flex-col gap-5 py-16 px-8 text-sm md:text-base'>
                                    <li>
                                    <span className='opacity-70'>Visual Data Representation: Complies with WCAG guideline 1.3.1 by using visual representations for data, such as graphs and charts, to make financial information more understandable.</span>
                                    </li>
                                    <li>
                                    <span className='opacity-70'>Navigational Clarity: Each app page has a clearly titled header (WCAG 2.4.2) and a highlighted icon indicating the current page (WCAG 2.4.3), simplifying navigation and enhancing user orientation.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id='key-features' className='flex flex-col md:flex-row justify-between my-14'>
                <div className='md:w-[50%] flex flex-col justify-between'>
                    <div className='bg-white flex flex-col'>
                        <h1 className="work-subhead text-[42px] font-bold pr-[30%]">Key features & Insights</h1>
                        <p className='text-[#667085] text-[16px] pr-[30%]'>The research process provided several insights into possible features for Versal finance</p>
                    </div>
                    <div className='mt-5 md:mt-0 bg-[#E1EAD9] rounded-l-2xl p-14'>
                        <div className='bg-[#C3D4B4] p-8 rounded-2xl'>
                            <h3 className='font-semibold text-xl'>Accessibility challenges</h3>
                            <ul className='list-disc p-3'>
                                <li>These challenges included difficulties in navigation complex interfaces</li>
                                <li>Lack of alternative text for images</li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div className='mt-5 md:mt-0 md:w-[50%] rounded-r-2xl rounded-tl-2xl bg-[#E1EAD9] p-14'>
                    <div className='bg-[#C3D4B4] p-8 rounded-2xl'>
                        <h2 className='font-semibold text-[17px] mb-5'>Key features</h2>
                        <div className='key-features flex flex-col gap-4'>
                            <div>
                                <h3>Expense Tracking:</h3>
                                <p> It enables users to easily track and monitor their spending in order to learn more about their spending patterns</p>
                            </div>
                            <div>
                                <h3>Budget Monitoring:</h3>
                                <p>Users can build and manage budgets with Versal Finance to ensure they remain within their tight budgets.</p>
                            </div>
                            <div>
                                <h3>Inflow and Outflow analytics:</h3>
                                <p>Users can view complete analyses of their daily, weekly, monthly, and quarterly money inflows and outflows to help them make better financial decisions.</p>
                            </div>
                            <div>
                                <h3>Multi-Account sync</h3>
                                <p>They can sync with several bank accounts, collecting financial records in one platform.</p>
                            </div>
                            <div>
                                <h3>Personalized spending tracking</h3>
                                <p>Users may group costs into several categories and monitor spending trends for each one.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div id='ideation'>
                <h1 className="work-subhead font-semibold">Ideation and prototyping</h1>
                <LazyLoadImage 
                    src='/assets/work/versalfinance/ideation-cover.png'
                    className='w-full h-full'
                    effect='blur'
                    alt='alt'
                />
                <div className='text-[#6E6E6E] my-7 text-sm md:text-base flex flex-col gap-8'>
                    <p>
                    After defining the Versal finance structure, i started by outlining the key features and functionalities i envision for Versal, considering user needs and pain points i identified during the research process. I also sketched a basic layout structure, to determining the placement of essential elements like navigation bars, transaction history and accoubt details.I also created a simplified wireframe, outlining the main screens of the app. This included the home screen, transcaction history, budget etc.
                    </p>
                    <p>
                    Drew arrows to indicate the flow of the app navigation between different screens. I ensured a logical and user friendly progression through the app.Positioned key elements such as buttons,icons, and information fields within each screen.I considered the hierarchy of information and the visual balance of the interface.
                    </p>
                </div>
                <div>
                    <h2 className="mini-head lg:mt-16">User flow</h2>
                    <p className='text-[#6E6E6E] pr-[50%] my-8'>The insight i got from the user interviews we conducted led to this user personas</p>
                    <LazyLoadImage 
                        src='/assets/work/versalfinance/user-flow-chart.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                </div>
                <div className='my-16'>
                    <h2 className="mini-head">User Journey Map</h2>
                    <p className='my-5'>User journey map was created to highlight several</p>
                    <LazyLoadImage 
                        src='/assets/work/versalfinance/user-journey-map.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                </div>
            </div>
            <div id='sketches' className='my-12'>
                <h1 className="work-subhead font-semibold">Sketches</h1>
                <p className='my-5'>User journey map was created to highlight several</p>
                <div className='flex flex-col md:flex-row'>
                    <LazyLoadImage 
                        src='/assets/work/versalfinance/sketches-1.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/versalfinance/sketches-2.png'
                        className='w-full h-full'
                        effect='blur'
                        alt='alt'
                    />
                </div>
                <div>
                    <h2 className="mini-head lg:mt-10">Colour Pallettes</h2>
                    <p className='my-5'>
                    The colour palletes were carefully selected using a combination of color throughout the app.These palletes are carefully curated to create a visuallypleasing and harmonious design that also aaligns with the brand identity.
                    </p>
                    <LazyLoadImage 
                        src='/assets/work/versalfinance/color-pallette.png'
                        className='w-full h-full my-8'
                        effect='blur'
                        alt='alt'
                    />
                </div>
                <div>
                    <h2 className="mini-head lg:mt-10">Typography</h2>
                    <p className='my-5'>The colour palletes were carefully selected using a combination of color throughout the app.These palletes are carefully curated to create a visuallypleasing and harmonious design that also aaligns with the brand identity.</p>
                    <div className='flex flex-col md:flex-row justify-between'>
                        <LazyLoadImage 
                            src='/assets/work/versalfinance/typography-1.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                         <LazyLoadImage 
                            src='/assets/work/versalfinance/typography-2.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                    </div>
                </div>
            </div>
            <div id='high-fidelity' className='my-12'>
                <h1 className="work-subhead font-semibold">High Fidelity Screens</h1>
                <p></p>
                <div>
                    <div className='bg-[#F1F1F1] py-5 px-1 flex flex-col gap-4'>
                        <LazyLoadImage 
                            src='/assets/work/versalfinance/high-fidelity-1.png'
                            className='w-full'
                            effect='blur'
                            alt='alt'
                        />
                        <LazyLoadImage 
                            src='/assets/work/versalfinance/high-fidelity-2.png'
                            className='w-full'
                            effect='blur'
                            alt='alt'
                        />
                        <LazyLoadImage 
                            src='/assets/work/versalfinance/high-fidelity-3.png'
                            className='w-full'
                            effect='blur'
                            alt='alt'
                        />
                    </div>
                    <div>
                        <h2 className="mini-head lg:mt-16">Prototyping</h2>
                        <div>
                            <div className='flex flex-col md:flex-row my-5'>
                                <LazyLoadImage 
                                    src='/assets/work/versalfinance/prototyping-1.png'
                                    className='w-full h-full'
                                    effect='blur'
                                    alt='alt'
                                />
                                <LazyLoadImage 
                                    src='/assets/work/versalfinance/prototyping-2.png'
                                    className='w-full h-full'
                                    effect='blur'
                                    alt='alt'
                                />
                            </div>
                            <div>
                                <iframe loading='lazy' className='w-full h-[25vh] sm:h-[30vh] md:h-[40vh]' src="https://drive.google.com/file/d/1yo5VggO037Ewwe7-F8-0bKOD3Fxx0rZr/preview" allow="autoplay"></iframe>
                            </div>
                            {/* <LazyLoadImage 
                                src='/assets/work/versalfinance/prototyping-3.png'
                                className='w-full h-full'
                                effect='blur'
                                alt='alt'
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
            <div id='usability-testing'>
                <h1 className="work-subhead font-semibold">Usability testing</h1>
                <div className='flex flex-col md:flex-row justify-between gap-5'>
                    <div className='md:w-[33.3%] flex flex-col gap-5 text-[#6E6E6E] text-sm border rounded-2xl border-black py-8 px-3'>
                        <h3 className='text-[#1B4BB7] font-semibold my-5'>Study Overview:</h3>
                        <p>
                            <bold>Objective:</bold>
                            The main purpose of the usability test was to evaluate the functionality of the Versal app, confirm design decisions, and understand how users interact with the application 
                        </p>
                        <p>
                            <bold>Participants</bold>
                            The test involved 10 participants aged 25-35, with a gender distribution of 6 males and 4 females. None of the participants had disabilities.
                        </p>
                        <p>
                            <bold>Methodology</bold>
                            Using mobile phones, participants were briefed on user personas and asked to perform specific tasks within a 10-minute timeframe. Tools used included the System Usability Scale (SUS) for quantitative feedback and the think-aloud protocol for qualitative insights
                        </p>
                    </div>
                    <div className='md:w-[33.3%] flex flex-col gap-5 text-[#6E6E6E] text-sm border rounded-2xl border-black py-8 px-3'>
                        <h3 className='text-[#1B4BB7] font-semibold my-5'>Key Perfomance Indications:</h3>
                        <p>
                            <bold>Task Success Rate:</bold>
                            Task success rate was measured for various user tasks. The KPI for this metric was set at a minimum of 65%,and the actual success rate averaged 87%
                        </p>
                        <p>
                            <bold>Time on Task:</bold>
                            The KPI for time on task was set to ensure that tasks were completed within a reasonable time frame. Tasks were considered efficient if completed within a set time limit(e.g., under 10 minutes), and the actual completion times met these criteria.
                        </p>
                        <p>
                            <bold>User Satisfaction</bold>
                            User satisfaction was measured using a 5-point Likert scale (1-Very dissatisfied to 5-Very Satisfied). The KPI was set at an average satisfaction rating of 4.5, and the actual average rating was 4.8.
                        </p>
                    </div>
                    <div className='md:w-[33.3%] flex flex-col gap-5 text-[#6E6E6E] text-sm border rounded-2xl border-black py-8 px-3'>
                        <h3 className='text-[#1B4BB7] font-semibold my-5'>Result and Analysis</h3>
                        <p>
                            <bold>Quantitative Outcomes:</bold>
                            The Versal app achieved a SUS score of 80.75, indicating good usability with potential areas for improvement.
                        </p>
                        <p>
                            <bold>Qualitative Feedback:</bold>
                            Participants generally found the app easy to use, with intuitive navigation and a straightforward layout that facilitated tasks like creating budgets and accessing financial summaries.
                        </p>
                        <p>
                            <bold>Insights</bold>
                            The think-aloud protocol provided valuable data on user cognitive processes and interaction experiences, highlighting the app's effectiveness and accessibility.
                        </p>
                    </div>
                </div>
                <div className='my-16'>
                    <h2 className="mini-head">Updates after testing</h2>
                    <div className='py-10 grid grid-cols-1 md:grid-cols-2 gap-8'>
                        <div className='update-testing-card'>
                            <p className='index'><span>1</span>/6</p>
                            <p className=''>
                                <bold>Reduction of the onboarding process : </bold>
                                <span className='opacity-70'>The onboarding process was streamlined by eliminating several steps and adopting a familiar method, allowing users to create an account upfront by merging the sign-up process, including email and password requirements.</span>
                            </p>
                        </div>
                        <div className='update-testing-card'>
                            <p className='index'><span>2</span>/6</p>
                            <p>
                                <bold>Improve Navigation: </bold>
                                <span className="opacity-70"> During the testing phase, few of the participants suggested The navigation was improved by allowing users to create a budget right from the homepage.</span>
                            </p>
                        </div>
                        <div className='update-testing-card'>
                            <p className='index'><span>3</span>/6</p>
                            <p>
                                <bold>Breakdown clarification :  </bold>
                                <span className="opacity-70">The "see breakdown” text" was modified to see accounts with a clear label, as well as increasing the text size and expanding the clickable area.</span>
                                
                            </p>
                        </div>
                        <div className='update-testing-card'>
                            <p className='index'><span>4</span>/6</p>
                            <p>
                                <bold>Enhance feature integration : </bold>
                                <span className="opacity-70">
                                A book recommendation is supplied as an extra bonus if the user's budget hits 80%.
                                </span>
                            </p>
                        </div>
                        <div className='update-testing-card'>
                            <p className='index'><span>5</span>/6</p>
                            <p>
                                <bold>Color code destructive action : </bold>
                                <span className="opacity-70">
                                Color coding was used to emphasize destructive operations such as budget deletion.
                                </span>
                            </p>
                        </div>
                        <div className='update-testing-card'>
                            <p className='index'><span>6</span>/6</p>
                            <p>
                                <bold>Maintain Consistency in Design: </bold>
                                <span className="opacity-70">
                                Consistent color coding was used to reduce confusion for different states in the budgeting section.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div id='dark-mode'>
                <h1 className="work-subhead">Dark Mode</h1>
                <p className='my-5'>Upon learning that 80% of iphone user prefers Dark mode over Light mode, i implemented the use of Figma color variables and also from the settings page where they can customise font colors. The darkmode feature in Versal  is crafted to offer users a distinct Visual experience that is gentle on the eyes,particularly in low-light conditions.</p>
                <div className='grid grid-cols-2 md:flex bg-[#343434] gap-3 justify-between'>
                    <LazyLoadImage 
                        src='/assets/work/versalfinance/dark-mode-1.png'
                        className='w-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/versalfinance/dark-mode-2.png'

                        className='w-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/versalfinance/dark-mode-3.png'
                        className='w-full'
                        effect='blur'
                        alt='alt'
                    />
                    <LazyLoadImage 
                        src='/assets/work/versalfinance/dark-mode-4.png'
                        className='w-full'
                        effect='blur'
                        alt='alt'
                    />
                </div>
            </div>
            <div id='multi-platform-accessibility'>
                <h1 className="work-subhead font-semibold mt-16">Multi-platform Accessibility</h1>
                <p className='mb-8 mt-3 text-[15px]'>
                According to WCAG guideline 1.4.10 (Reflow), an inclusive approach was used beyond the mobile application, taking into account additional displays such as iPads and computers. This consideration arises from studies that show that bigger displays might assist a variety of user groups, including individuals with physical, cognitive, visual, and auditory/mild hearing difficulties). In accordance with WCAG guideline 1.4.4, such displays give adequate room for content presentation, contributing to improved readability and user interaction (Resize Text). 
                </p>
                <p className='text-[15px] mb-8'>
                Particularly, users with low vision, notably those with diabetic retinopathy, benefit from the magnified display available on bigger displays, which improves accessibility in accordance with WCAG guideline 1.4.5. (Images of Text). Furthermore, those with physical disabilities who may struggle with small touch targets benefit greatly from the iPad's bigger screen, which fits with WCAG guideline 2.5.5. (Target Size).
                </p>
                <p>
                Furthermore, the design implemented several spaces between interface elements, adhering to WCAG guideline 2.5.6 (Concurrent Input Mechanisms), effectively addressing the concern of inadvertent taps on incorrect elements. This design choice enhances user experience by providing ample room for interaction while preventing potential input errors.
                </p>
                <div className='my-8'>
                    <div className='py-8'>
                        <h2 className='font-bold'>Desktop view</h2>
                        <div className='flex flex-col md:flex-row justify-between gap-3'>
                            <LazyLoadImage 
                                src='/assets/work/versalfinance/desktop-view-1.png'
                                className='w-full h-full'
                                effect='blur'
                                alt='alt'
                            />
                            <LazyLoadImage 
                                src='/assets/work/versalfinance/desktop-view-2.png'
                                className='w-full h-full'
                                effect='blur'
                                alt='alt'
                            />
                        </div>
                    </div>
                    <div className='py-8'>
                        <h2 className='font-bold'>Tab view</h2>
                        <div className='flex flex-col md:flex-row justify-between gap-3'>
                            <LazyLoadImage 
                                src='/assets/work/versalfinance/tab-view-1.png'
                                className='w-full h-full'
                                effect='blur'
                                alt='alt'
                            />
                            <LazyLoadImage 
                                src='/assets/work/versalfinance/tab-view-2.png'
                                className='w-full h-full'
                                effect='blur'
                                alt='alt'
                            />
                        </div>
                    </div>
                </div>
                <div className='pt-8'>
                    <h2 className="mini-head">ONBOARDING VERSAL</h2>
                    <div className='text-sm flex flex-col md:flex-row my-5 justify-between items-start'>
                        <div className='md:w-[40%]'>
                            <h2 className='font-semibold text-4xl my-3'>Creating an account</h2>
                            <p>Versal was created with top-notch security features when you sign up.It offers a straightforward onboarding process, guiding users through several verifications to ensure their money is safe during registeration.</p>
                        </div>
                        <div className='md:w-[35%] bg-[#FCEFD0] p-3'>
                            <h3>Accessibility consideration (WCAG)</h3>
                            <ul className='list-disc px-4'>
                                <li>Descriptive text identification (3.2.4 AA)</li>
                                <li>Label (2.4.6 AA)</li>
                                <li>Input field ( 1.3.5 Identity purpose)</li>
                                <li>Use color and text (3.3.3 A)</li>
                            </ul>
                        </div>
                    </div>
                    <div className='py-8'>
                        <ul className='grid grid-cols-2 md:flex justify-between'>
                            {[1,2,3,4].map((item) => (
                                <LazyLoadImage
                                    key={item} 
                                    src={`/assets/work/versalfinance/create-versal-acc-${item}.png`}
                                    className='w-full h-full'
                                    effect='blur'
                                    alt='alt'
                                />
                            ))}
                        </ul>
                    </div>
                    <div className='py-10'>
                        <h2 className="mini-head">HOME</h2>
                        <div className='flex flex-col md:flex-row gap-10'>
                            <div className='w-full'>
                                <LazyLoadImage
                                    src='/assets/work/versalfinance/versal-home-page.png'
                                    className='w-full h-full'
                                    effect='blur'
                                    alt='alt'
                                />
                            </div>
                            <div className='text-sm'>
                                <p className='my-3'>The main screen of the app is made in a way users can easily see breakdowns ffrom different account and link their other bank accounts directly. They can also view their total balance.The home page is labeled for accesible page labeling and the use of voice command is also available in versal.</p>
                                <LazyLoadImage
                                    src='/assets/work/versalfinance/versal-break-1.png'
                                    className='w-full h-full'
                                    effect='blur'
                                    alt='alt'
                                />

                                <p className='my-4'>Another exciting thing is the use of charts for analytics which shows the inflow and outflow (spending) at every point. The recent transactions shows all the transactions from various linked accounts, it’s like seeing all expenses at a go!</p>

                                <LazyLoadImage
                                    src='/assets/work/versalfinance/versal-break-2.png'
                                    className='w-full h-full'
                                    effect='blur'
                                    alt='alt'
                                />

                                <p className="my-3">Budgeting is the main aspect of the application and users can easily view directly from the homepage how much they’ve budgeted and how much they’ve spent out of it.</p>

                                <LazyLoadImage
                                    src='/assets/work/versalfinance/versal-break-3.png'
                                    className='w-full h-full'
                                    effect='blur'
                                    alt='alt'
                                />

                                <p className="my-3">Learning is always an intersting way to understand things better, Versal offers several learning materials to best understand how to go about budgeting or how to make life in budgeting easier for people</p>
                            </div>
                        </div>
                    </div>
                    <div className='py-10'>
                        <h2 className="mini-head">BUDGET</h2>
                        <div className='py-5'>
                            <p>The budgeting feature allows users to:  </p>
                            <p>- Set financial goals and targets - Create and manage categories (e.g. housing, transportation, food) - Track income and expenses - Assign transactions to categories - View detailed budget breakdowns and progress - Receive alerts and notifications for overspending or unusual activity - Adjust budget allocations and make adjustments as needed  This budgeting tool empowers users to take control of their finances, make informed decisions, and achieve financial stability.</p>
                        </div>
                        <div className="my-5 space-y-8">
                            <LazyLoadImage
                                src='/assets/work/versalfinance/versal-budget-1.png'
                                className='w-full h-full'
                                effect='blur'
                                alt='alt'
                            />
                            <LazyLoadImage
                                src='/assets/work/versalfinance/versal-budget-2.png'
                                className='w-full h-full'
                                effect='blur'
                                alt='alt'
                            />
                            <div className='flex flex-col md:flex-row gap-10 justify-between items-center'>
                                <div className='w-full'>
                                    <LazyLoadImage
                                        src='/assets/work/versalfinance/versal-budget-3.png'
                                        className='w-full h-full'
                                        effect='blur'
                                        alt='alt'
                                    />

                                </div>
                                <div className='space-y-4 text-sm lg:text-base'>
                                    <p>The budget home page offers a comprehensive overview of your financial progress, providing a clear indication of whether you're on track to meet your goals. </p>
                                    <p>From this central hub, you can monitor monthly budget trends, add new categories, and make informed decisions to stay financially on track, ensuring you're always in control of your financial well-being.The budget home page offers a comprehensive overview of your financial progress, providing a clear indication of whether you're on track to meet your goals.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='py-10'>
                        <h2 className="mini-head">ANALYTICS</h2>
                        <div className='my-5 space-y-1 text-sm md:text-base'>
                            <p>The analytics section provides users with a visual and interactive overview of their financial data, enabling them to:</p>
                            <p>- Track income and expense trends over time    - View category-wise spending breakdowns and percentages</p>
                            <p>- Analyze transaction patterns and identify areas for improvement  - Monitor progress towards financial goals and targets  - Explore detailed insights and statistics on spending habits</p>
                            <p>- Use interactive charts and graphs to visualize financial performance.This analytics tool helps users gain valuable insights, identify areas for optimization, and make data-driven decisions to achieve financial wellness.</p>
                        </div>
                        <div className='my-8'>
                            <ul className='grid grid-cols-2 md:flex gap-4 justify-between'>
                                {[1,2,3,4].map((item) => (
                                    <LazyLoadImage
                                        key={item} 
                                        src={`/assets/work/versalfinance/versal-analytics-${item}.png`}
                                        className='w-full'
                                        effect='blur'
                                        alt='alt'
                                    />
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div>
                        <h2 className="mini-head">SETTINGS</h2>
                        <div className="my-5 text-sm md:text-base">
                            <p> The settings aspect provides users with a centralized hub to manage their profile, connected accounts, notifications, and appearance preferences. </p>
                            <div className='mt-5'>
                                <p>Profile - View and edit personal information - Update password and security settings</p>
                                <p> Account Link  - Connect and manage multiple bank accounts - Enable/disable account syncing </p>
                                <p> Notifications  - Customize alert preferences for transactions, low balance, and payment reminders - Set notification frequency and timing</p>
                                <p> Appearance  - Choose from various themes and color schemes - Customize font sizes and display settings .</p>
                            </div>
                            <div className='my-10'>
                                <LazyLoadImage 
                                    src='/assets/work/versalfinance/versal-settings.png'
                                    className='w-full h-full'
                                    effect='blur'
                                    alt='alt'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='py-10'>
                        <h2 className="mini-head">Help and Live Chat</h2>
                        <p className="my-4 text-sm md:text-base">For real-time assistance, I designed Versal to offer a live chat support feature. This allows users to interact with a support representative instantly, getting timely help for their concerns.</p>
                        <LazyLoadImage 
                            src='/assets/work/versalfinance/versal-help.png'
                            className='w-full h-full'
                            effect='blur'
                            alt='alt'
                        />
                    </div>
                </div>
            </div>     
        </div>
     </WorkLayout>
  )
}

export default VersalFinance