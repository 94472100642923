import React from 'react'

const Connector = () => {
  return (
    <svg width="62" height="17" viewBox="0 0 62 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.937385 8.41532C0.937385 7.50308 1.67691 6.76356 2.58915 6.76356C3.5014 6.76356 4.24092 7.50308 4.24092 8.41532C4.24092 9.32757 3.5014 10.0671 2.58915 10.0671C1.67691 10.0671 0.937385 9.32757 0.937385 8.41532Z" stroke="#036358" strokeWidth="1.10118" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.7915 7.86494L4.24092 7.86494L4.24092 8.96612L4.7915 8.96612L4.7915 7.86494ZM4.7915 8.96612L52.1421 8.96612L52.1421 7.86494L4.7915 7.86494L4.7915 8.96612Z" fill="#036358"/>
        <path d="M52.1421 7.86445L51.5915 7.86445L51.5915 8.96563L52.1421 8.96563L52.1421 7.86445ZM61.3408 8.80436C61.5558 8.58934 61.5558 8.24073 61.3408 8.02571L57.8369 4.52179C57.6219 4.30677 57.2733 4.30677 57.0583 4.52179C56.8432 4.73681 56.8432 5.08542 57.0583 5.30044L60.1729 8.41504L57.0583 11.5296C56.8432 11.7447 56.8432 12.0933 57.0583 12.3083C57.2733 12.5233 57.6219 12.5233 57.8369 12.3083L61.3408 8.80436ZM52.1421 8.96563L60.9515 8.96563L60.9515 7.86445L52.1421 7.86445L52.1421 8.96563Z" fill="#036358"/>
    </svg>
  )
}

export default Connector