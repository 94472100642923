import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import ScrollToTop from './scrollToTop'


const Layout = ({ children, className }) => {
  return (
    <section className={`font-inter ${className} flex flex-col min-h-screen`}>
        <ScrollToTop />
        <Navbar />
        {/* <span className='flex w-full border-b-2 opacity-50 border-black'></span> */}
        <main className=''>
            {children}
        </main>
        <Footer />
    </section>
  )
}

export default Layout